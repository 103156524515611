import {
  CONNECTED_DEVICE,
  RESTORE_BUTTON_TAB_CONF,
  SET_FATAL_LOG,
  USER_CLICK_MENU,
  CHANGE_APP_LANG,
} from "./constants";
import SendDataToHID from "./sendData";
import { getCommandByName } from "../keymap/ConcreteKeymap";
import { getPredefinedKeyById } from "../keymap/SystemKeyMap";
import { convertIntToHexString } from "./constants";
import { toBeRequired } from "@testing-library/jest-dom/matchers";

// console.log = function () {};
// console.warn = function () {};

export function getInitialLanguage() {
  const userLanguage =
    navigator.language ||
    navigator.browserLanguage ||
    navigator.systemLanguage ||
    navigator.userLanguage ||
    "";

  if (userLanguage.length === 0) {
    return "zh";
  }

  let langCode = userLanguage.substring(0, 2).toLowerCase();
  if (langCode === "zh") {
    return "zh";
  } else {
    return "en";
  }
}

function buttonMapObj(tabIndex, value) {
  this.tabIndex = tabIndex;
  this.mapValue = value;
}

const initialState = {
  // productName: "",
  // connectedDevices: [],
  // isConnected: false,

  topMenuIndex: 0,

  deviceList: [], // {text, picture}

  buttonMap: {
    //ButtonMap 按键映射。
    // 每一个按键可以映射成任意按键，甚至是不存在的多媒键，组合键，鼠标按键以及录制的宏。
    // 所以需要抽象出一个对象来标示要映射的对象。
    // The Key map assigns a function to the key. This can be a normal key-press, or a special feature
    // (macro, media-key, etc) Each key has a two-byte configuration.
    layerIndex: 0,
    // Row * 100 + column ==> new buttonMapObj(index, value_UID).
    mappingData: [{}, {}, {}, {}], // keyid:{showText, uid}
    bottomTabIndex: 0, //底层按的Ｔab索引，　０开始计数，也是buttonMapObj中的tabIndex算是映射类别。

    //drag.
    dragFlag: false,
    mouseUpY: -800,
    mouseUpX: -800,

    keyboardBelowClickValue: -1,
    keyboardBelowClickUid: -1,
    belowUpdateFlag: false,

    mapPendingKey: [],
  },

  macroMap: {
    hintText: "",
    mask: false,
    showConfigureUI: false,
    selectIndex: 0,
    defaultMacros: [
      { name: "M1", storeIndex: -1, actionList: [] },
      { name: "M2", storeIndex: -1, actionList: [] },
      { name: "M3", storeIndex: -1, actionList: [] },
      { name: "M4", storeIndex: -1, actionList: [] },
      { name: "M5", storeIndex: -1, actionList: [] },
      { name: "M6", storeIndex: -1, actionList: [] },
      { name: "M7", storeIndex: -1, actionList: [] },
      { name: "M8", storeIndex: -1, actionList: [] },
      { name: "M9", storeIndex: -1, actionList: [] },
      { name: "M10", storeIndex: -1, actionList: [] },
    ],

    //macro list
    //   macro = {
    //     name: name,
    //     storeIndex: above_macroIndex
    //     hidIndex: sequence stored in hid devices.
    //     actionList: recordActions,
    //   };

    //   let action = {
    //     name: eventName,
    //     isDown: false
    //     timeStamp: event.timeStamp,
    //     duration: (duration / 1000.0).toFixed(2),
    //   };
    // macroLists: [],
  },

  fatalLog: "",

  appConfig: {
    lang: getInitialLanguage(),
  },
};

function commonReducer(state = initialState, action) {
  //console.log("We reducer get action ", action, "our old state is", state);

  switch (action.type) {
    case SET_FATAL_LOG:
      return { ...state, fatalLog: action.payload };
    case "USER_CLICK_TOP_MENU_ACTION":
      return { ...state, topMenuIndex: action.payload };

    case "USER_CLICK_LAYER_ACTION": {
      let buttonMap = JSON.parse(JSON.stringify(state.buttonMap));
      buttonMap.layerIndex = action.payload;
      return { ...state, buttonMap: buttonMap };
    }

    case "USER_CLICK_BOTTOM_TAB_ACTION": {
      let buttonMap = JSON.parse(JSON.stringify(state.buttonMap));
      buttonMap.bottomTabIndex = action.payload;
      return { ...state, buttonMap: buttonMap };
    }

    case "USER_CLICK_START_DRAG_ACTION": {
      console.warn(
        "USER_CLICK_START_DRAG set below click value",
        action.payload
      );
      let buttonMap = JSON.parse(JSON.stringify(state.buttonMap));
      buttonMap.dragFlag = true;
      buttonMap.keyboardBelowClickValue = action.payload;
      buttonMap.keyboardBelowClickUid = action.uid;
      console.warn("CLICK_START_DRAG update below value", action.payload);
      return {
        ...state,
        buttonMap: buttonMap,
      };
    }
    case "USER_SET_DRAG_MAPPING_ACTION": {
      let buttonMap = JSON.parse(JSON.stringify(state.buttonMap));
      buttonMap.mapPendingKey = action.payload;
      console.warn("MIDDLE_CLICK_CANVAS", action.payload);

      if (buttonMap.keyboardBelowClickValue.length > 0) {
        console.warn(
          "USER_CLICK_MAP_PENDING_KEY map new key to value",
          buttonMap.keyboardBelowClickValue,

          buttonMap.mapPendingKey + " ==> " + buttonMap.keyboardBelowClickValue
        );

        let currentLayerMapArray = buttonMap.mappingData[buttonMap.layerIndex];
        currentLayerMapArray[buttonMap.mapPendingKey] = {
          showText: buttonMap.keyboardBelowClickValue,
          uid: buttonMap.keyboardBelowClickUid,
        };
      }

      return { ...state, buttonMap: buttonMap };
    }

    case "USER_CLICK_MAP_PENDING_KEY": {
      let buttonMap = JSON.parse(JSON.stringify(state.buttonMap));
      buttonMap.mapPendingKey = action.payload;
      buttonMap.keyboardBelowClickValue = "";
      buttonMap.keyboardBelowClickUid = "invalid";
      console.warn("MIDDLE_CLICK_CANVAS", action.payload);
      return { ...state, buttonMap: buttonMap };
    }

    case "USER_CLICK_KEYBOARD_BELOW":
      let buttonMap2 = JSON.parse(JSON.stringify(state.buttonMap));

      buttonMap2.keyboardBelowClickValue = action.payload;
      buttonMap2.belowUpdateFlag = !buttonMap2.belowUpdateFlag;

      console.warn("CLICK_DIRECT_CLICK update below value", action.payload);
      buttonMap2.keyboardBelowClickUid = action.uid;

      if (buttonMap2.mapPendingKey.length > 0) {
        console.warn(
          "CLICK_DIRECT_CLICK update below value",
          action.payload,
          " setup the new mapping",
          buttonMap2.mapPendingKey + " ==> " + action.payload
        );
        let currentLayerMapArray =
          buttonMap2.mappingData[buttonMap2.layerIndex];
        currentLayerMapArray[buttonMap2.mapPendingKey] = {
          showText: action.payload,
          uid: action.uid,
        };
      }

      return {
        ...state,
        buttonMap: buttonMap2,
      };

    case "USER_SELECT_NEW_DEVICE":
      let newObj = { picture: action.picture, type: action.name };

      if (!state.deviceList.some((item) => item.type === action.name)) {
        let deviceList = JSON.parse(JSON.stringify(state.deviceList));
        deviceList.push(newObj);

        return { ...state, deviceList: deviceList };
      } else {
        return state;
      }

    case "USER_CLICK_STOP_DRAG_ACTION":
      let buttonMap1 = JSON.parse(JSON.stringify(state.buttonMap));
      buttonMap1.dragFlag = false;

      return {
        ...state,
        buttonMap: buttonMap1,
      };
    //

    case "MACRO_EMPTY_HINTS": {
      let macroMap = JSON.parse(JSON.stringify(state.macroMap));
      macroMap.hintText = action.payload;
      macroMap.mask = true;
      return {
        ...state,
        macroMap: macroMap,
      };
    }

    case "MACRO_CONFIGURE_UI": {
      let macroMap = JSON.parse(JSON.stringify(state.macroMap));
      macroMap.hintText = "";
      macroMap.showConfigureUI = action.payload;
      macroMap.selectIndex = action.index;
      macroMap.mask = true;
      return {
        ...state,
        macroMap: macroMap,
      };
    }

    case "MACRO_REPLACED": {
      let macroMap = JSON.parse(JSON.stringify(state.macroMap));
      let defaultMacros = JSON.parse(JSON.stringify(macroMap.defaultMacros));
      defaultMacros[action.index] = action.payload;

      macroMap.defaultMacros = defaultMacros;
      return { ...state, macroMap: macroMap };
    }

    case "CLEAR_ALL_POPUP": {
      let macroMap = JSON.parse(JSON.stringify(state.macroMap));
      macroMap.hintText = "";
      macroMap.showConfigureUI = false;
      macroMap.mask = false;
      return {
        ...state,
        macroMap: macroMap,
      };
    }

    // draw dragged blue circle.
    case "USER_CLICK_MOUSE_UP_POS": {
      let buttonMap = JSON.parse(JSON.stringify(state.buttonMap));
      buttonMap.mouseUpX = action.x;
      buttonMap.mouseUpY = action.y;
      return { ...state, buttonMap: buttonMap };
    }

    case USER_CLICK_MENU:
      return { ...state, menu: action.payload };

    case CHANGE_APP_LANG:
      let currentconfig = { ...state.appConfig };
      currentconfig.lang = action.payload;

      let result = { ...state, appConfig: currentconfig };
      console.log("We get app config", currentconfig, result);
      return result;

    case "RESTORE_ALL_CONFIGS":
      return initialState;

    case RESTORE_BUTTON_TAB_CONF:
      let restoreObj = JSON.parse(JSON.stringify(state.buttonMap));
      restoreObj[action.payload] = [{}, {}, {}, {}, {}, {}];

      let swapObj1 = JSON.parse(JSON.stringify(state.swapLeftRight));
      swapObj1[action.payload] = false;

      dispatchWholeButtonConf(restoreObj[action.payload], false);
      return { ...state, buttonMap: restoreObj, swapLeftRight: swapObj1 };

    case "FILL_FIRMWARE_VERSION":
      return { ...state, firmwareVersion: action.payload };

    case "FILL_BATTERY_INFO":
      return { ...state, batteryInfo: action.payload };

    default:
      return state;
  }
}

function dispatchWholeButtonConf(readObject, swap) {
  let confObject = [...readObject];
  // swap content of 3, 4, hardware bug.
  let middleSwap = confObject[3];
  confObject[3] = confObject[4];
  confObject[4] = middleSwap;

  if (swap) {
    let middleSwap = confObject[0];
    confObject[0] = confObject[1];
    confObject[1] = middleSwap;
  }

  console.warn("We are sending data", confObject, "swap is", swap);

  let finalCommands = "DF 07 33"; //including length　33.
  finalCommands += swap ? " 46 03 01 " : " 46 03 00 "; //not switch left right.
  let allSubCmd = "";

  let storeIndex2hidIndexMap = new Map();
  let hidIndex = 0;

  confObject.forEach((payload, index) => {
    let buttonIndex = index + 1;

    let TLandButtonIndex =
      "48 08 " + convertIntToHexString(buttonIndex, 1) + " ";
    let subComd = "";

    if (payload.category === 0) {
      let keyBits = payload.dataIndex;
      console.log("----------keyBits " + keyBits.toString(2).padStart(8, "0"));

      subComd =
        "05 " +
        convertIntToHexString(keyBits, 1) +
        " " +
        getCommandByName(payload.extraData) +
        " 00 00";
    } else if (payload.category === 1) {
      //macro set.
      let usingIndexInThisLoop = 0;
      if (!storeIndex2hidIndexMap.has(payload.dataIndex)) {
        storeIndex2hidIndexMap.set(payload.dataIndex, hidIndex);
        usingIndexInThisLoop = hidIndex;
        hidIndex++;
      } else {
        usingIndexInThisLoop = storeIndex2hidIndexMap.get(payload.dataIndex);
      }
      let hidIndexStr = convertIntToHexString(usingIndexInThisLoop, 1);

      subComd = "10 " + hidIndexStr + " 77 "; // Macro index is alway 01 TODO

      if (payload.extraData > 30000) {
        let count = payload.extraData - 30000;
        let lastByte = count.toString(16).padStart(4, "0");
        subComd += lastByte.substring(0, 2) + " " + lastByte.substring(2);
      } else {
        subComd += macroMap[payload.extraData];
      }
    } else if (payload.category === 2) {
      subComd = getPredefinedKeyById(payload.dataIndex);
      if (subComd.length === 8) {
        subComd += " 00 00";
      }
    } else if (payload.category === 3) {
      subComd = "00 " + getCommandByName(payload.dataIndex) + " 00 00 00";
    } else {
      subComd = "ff ff ff 00 00";
    }

    allSubCmd += TLandButtonIndex + subComd + " ";
  });

  finalCommands += allSubCmd;
  console.log("FINAL command is " + finalCommands.trim());
  SendDataToHID(finalCommands);
}

const macroMap = {
  0: "00 00",
  1: "27 12",
  2: "27 11",
  3: "00",
};

export default commonReducer;
