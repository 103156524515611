function CommonButton({ text, blue, actionCallback }) {
  return (
    <button
      style={{
        border: "none",
        borderRadius: "5px",
        backgroundColor: blue ? "#0042da" : "#d9d9d9",
        margin: "0.3vh 0.8vh",
        padding: "0.6vh 1.9vh",
        cursor: "pointer",
      }}
      onClick={actionCallback}
    >
      {text}
    </button>
  );
}

export default CommonButton;
