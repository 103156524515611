import { StateStore } from "./statestore.js";
import { fillFirmwareVersion, fillBatteryInformationAction } from "./action.js";

function handleInputReport(event) {
  let dataArray = Array.from(new Uint8Array(event.data.buffer));
  const hexArray = dataArray.map((num) => num.toString(16).padStart(2, "0")); // Convert to hex

  console.log("We received some event", hexArray, event);

  if (hexArray[0] === "02" && hexArray[1] === "04") {
    // Step 2: Parse version from subsequent bytes
    const major = parseInt(hexArray[2], 16); // First byte as major version
    const minor = parseInt(hexArray[3], 16); // Second byte as minor version
    const patch = parseInt(hexArray[4], 16); // Third byte as patch version

    // Combine them into a version string
    const version = `${major}.${minor}.${patch}`;
    console.log("Firmware version " + version);
    StateStore.dispatch(fillFirmwareVersion(version));
  } else if (hexArray[0] === "02" && hexArray[1] === "25") {
    StateStore.dispatch(fillBatteryInformationAction(hexArray[2]));
  }
}

export default handleInputReport;
