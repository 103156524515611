import { useDispatch, useSelector } from "react-redux";
import CommonButton from "./CommonButton";
import { clearAllPopupAction, sendMacroReplaceAction } from "../webhid/action";
import SeparatorLine from "./SeparatorLine";
import { useCallback, useEffect, useState, useRef } from "react";
import { mouseKeyMap } from "../keymap/SystemKeyMap";
import { v4 as uuid } from "uuid";

function MacroConfigureUI({}) {
  const dispatch = useDispatch();
  const common = useSelector((state) => state.persistent.common);
  const defaultMacros = common.macroMap.defaultMacros;
  const selectIndex = common.macroMap.selectIndex;
  console.log("We leftMacroSelectIndex is " + selectIndex);
  const [leftMacroSelectIndex, setLeftMacroSelectIndex] = useState(selectIndex);

  const [actionSelectIndex, setActionSelectIndex] = useState(-1);
  const [pairedSelectIndex, setPairedSelectIndex] = useState(-1);

  const [recording, setRecording] = useState(false);

  const [editKeyName, setEditKeyName] = useState(false);
  //宏录制临时存储。
  // { }
  const [recordActions, setRecordActions] = useState(
    defaultMacros[selectIndex].actionList
  );

  const recordButtonRef = useRef(null);

  function calcPairedSelectIndex(selectIndex) {
    console.log("We try to find paired index for " + selectIndex);
    let isUp = recordActions[selectIndex].isUp;
    let pairedIndex = -1;
    if (isUp) {
      for (let i = selectIndex; i >= 0; i--) {
        if (
          recordActions[i].isDown === false &&
          recordActions[i].name === recordActions[selectIndex].name &&
          recordActions[i].isMouse === recordActions[selectIndex].isMouse
        ) {
          pairedIndex = i;
          break;
        }
      }
    } else {
      for (let i = selectIndex; i < recordActions.length; i++) {
        if (
          recordActions[i].isDown &&
          recordActions[i].name === recordActions[selectIndex].name &&
          recordActions[i].isMouse === recordActions[selectIndex].isMouse
        ) {
          pairedIndex = i;
          break;
        }
      }
    }

    return pairedIndex;
  }

  function setPairedSelectIndexWrapper(selectIndex) {
    let pairedIndex = calcPairedSelectIndex(selectIndex);

    if (pairedIndex == -1) {
      console.error("Non paired data found.");
      setPairedSelectIndex(-1);
      //   toast.warn(t("toast_no_paired"), {
      //     position: "top-center",
      //     autoClose: 2000,
      //     hideProgressBar: false,
      //     closeOnClick: true,
      //     pauseOnHover: true,
      //     draggable: true,
      //     progress: undefined,
      //     theme: "dark",
      //   });
    } else {
      setPairedSelectIndex(pairedIndex);
    }
  }
  const editEventFunc = useCallback(
    (event) => {
      if (event.type == "contextmenu") {
        //event.preventDefault();
        return;
      }

      let eventDesc = null;
      let isDown = true;
      let isMouse = true;
      if (event instanceof KeyboardEvent) {
        eventDesc =
          /*t("type")*/ "Key" +
          "-" +
          (event.key === " " ? event.code : event.key);
        isMouse = false;
        isDown = event.type === "keydown";
      } else if (event instanceof MouseEvent) {
        eventDesc = mouseKeyMap[event.button];
        isDown = event.type === "mousedown";
      }

      setRecordActions((prev) => {
        let newActions = JSON.parse(JSON.stringify(prev));
        if (actionSelectIndex >= 0) {
          newActions[actionSelectIndex].name = eventDesc;
        }
        window.removeEventListener("mouseup", editEventFunc);
        window.removeEventListener("mousedown", editEventFunc);
        window.removeEventListener("keydown", editEventFunc);
        window.removeEventListener("keyup", editEventFunc);
        window.removeEventListener("contextmenu", editEventFunc);
        setEditKeyName(false);

        return newActions;
      });
    },
    [recordActions, actionSelectIndex, editKeyName]
  );

  const recordEventFunc = useCallback(
    (event) => {
      if (event.type == "contextmenu") {
        //event.preventDefault();
        return;
      }

      let target = event.target;

      // 向上寻找，直到找到包含 `data-type="parent"` 的元素
      while (target && !target.dataset.type) {
        target = target.parentElement;
      }

      if (target && target.dataset.type === "eventTarget-Record") {
        // we stop the recording.
        return;
      }

      //if (event.target.value)
      if (event instanceof KeyboardEvent) {
        //event.preventDefault();
        console.log("We get key press", event.code);
      }

      let eventDesc = null;
      let isDown = true;
      let isMouse = true;

      if (event instanceof KeyboardEvent) {
        eventDesc =
          /*t("type")*/ "Key" +
          "-" +
          (event.key === " " ? event.code : event.key);
        isMouse = false;
        isDown = event.type === "keydown";
      } else if (event instanceof PointerEvent) {
        // event.type "mousedown", "mouseup"
        eventDesc = mouseKeyMap[event.button];
        isDown = false;
      } else if (event instanceof MouseEvent) {
        eventDesc = mouseKeyMap[event.button];
        isDown = event.type === "mousedown";
      }

      setRecordActions((prev) => {
        console.log("my length is", prev.length);
        if (prev.length > 50) {
          //   toast.warn(t("toast_fifty_limit"), {
          //     position: "top-center",
          //     autoClose: 2000,
          //     hideProgressBar: false,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     theme: "dark",
          //   });
          return prev;
        }

        let duration = 0;
        if (prev.length > 0) {
          duration = event.timeStamp - prev[prev.length - 1].timeStamp;
        }

        // if (prev.length === lastRecordLength) {
        //   duration = 0;
        // }

        let delay = duration.toFixed(2);

        // if (selectedRecordOption === "1") {
        // delay = (recordDelay / 1000.0).toFixed(3);
        // } else if (selectedRecordOption === "2") {
        //   delay = 0;
        // }

        const action = {
          isMouse: isMouse,
          name: eventDesc,
          key: uuid(),
          isDown: isDown,
          duration: delay,
          // extra, no display.
          timeStamp: event.timeStamp,
        };

        return [...prev, action];
      });
    },
    [
      /*selectedRecordOption, recordDelay, lastRecordLength*/
    ]
  );

  useEffect(() => {
    console.log("Add keydown listener");

    // window.addEventListener("keydown", recordEventFunc);
    // return () => {
    //   window.removeEventListener("keydown", recordEventFunc);
    // };
  }, []);

  function onActionButtonClicked(action) {
    if (recording && action !== "record") {
      // when we recording ignore all other clicks.
      return;
    }

    // if (schemeSelect === -1) {
    //   // no any selection, no any action.
    //   return;
    // }

    console.log("action " + action + " is clicked");
    if (action === "record") {
      setActionSelectIndex(-1);
      setPairedSelectIndex(-1);
      setRecording((prev) => {
        const recording = !prev;
        console.log("Recording state is now:", recording);
        if (recording) {
          window.addEventListener("mouseup", recordEventFunc);
          window.addEventListener("mousedown", recordEventFunc);
          window.addEventListener("keydown", recordEventFunc);
          window.addEventListener("keyup", recordEventFunc);
          window.addEventListener("contextmenu", recordEventFunc);
        } else {
          //   setLastRecordLength(recordActions.length);
          window.removeEventListener("mouseup", recordEventFunc);
          window.removeEventListener("mousedown", recordEventFunc);
          window.removeEventListener("keydown", recordEventFunc);
          window.removeEventListener("keyup", recordEventFunc);
          window.removeEventListener("contextmenu", recordEventFunc);
        }
        return recording;
      });
    } else if (action === "insert") {
      //   console.log("We select " + actionSelectIndex);
      //   let insertPlace = actionSelectIndex;
      //   if (actionSelectIndex === -1) {
      //     insertPlace = 0;
      //   }
      //   setRecordActions((prevState) => {
      //     const action1 = {
      //       isMouse: true,
      //       text1: mouseKeyMap[0],
      //       isUp: false,
      //       duration: INSERT_EVENT_DEFAULT_DELAY,
      //       key: uuid(),
      //       // extra, no display.
      //       timeStamp: -1,
      //     };
      //     const action2 = {
      //       isMouse: true,
      //       text1: mouseKeyMap[0],
      //       isUp: true,
      //       duration: INSERT_EVENT_DEFAULT_DELAY,
      //       key: uuid(),
      //       // extra, no display.
      //       timeStamp: -1, // -1 means this event is inserted manually.
      //     };
      //     return [
      //       ...prevState.slice(0, insertPlace),
      //       action1,
      //       action2,
      //       ...prevState.slice(insertPlace),
      //     ];
      //   });
      //   setActionSelectIndex(-1);
      //   setPairedSelectIndex(-1);
    } else if (action === "save") {
      //   if (schemeSelect !== -1) {
      //     if (!areEventsPaired(recordActions)) {
      //       toast.warn(t("toast_unpaired_event"), {
      //         position: "top-center",
      //         autoClose: 2000,
      //         hideProgressBar: false,
      //         closeOnClick: true,
      //         pauseOnHover: true,
      //         draggable: true,
      //         progress: undefined,
      //         theme: "dark",
      //         //transition: "Slide",
      //       });
      //       return;
      //     }

      const newMacro = JSON.parse(
        JSON.stringify(defaultMacros[leftMacroSelectIndex])
      );

      let macro = {
        ...newMacro,
        actionList: recordActions,
      };
      dispatch(sendMacroReplaceAction(leftMacroSelectIndex, macro));
    } else if (action === "reset") {
      setRecordActions([]);
      setActionSelectIndex(-1);
      setPairedSelectIndex(-1);
      const newMacro = JSON.parse(
        JSON.stringify(defaultMacros[leftMacroSelectIndex])
      );

      let macro = {
        ...newMacro,
        actionList: [],
      };
      dispatch(sendMacroReplaceAction(leftMacroSelectIndex, macro));
    } else if (action === "trim") {
      //   if (recordActions.length < 2) return;
      //   let main = recordActions.length - 1;
      //   setActionSelectIndex(main);
      //   let sub = calcPairedSelectIndex(main);
      //   if (sub === -1) {
      //     toast.warn(t("toast_no_paired"), {
      //       position: "top-center",
      //       autoClose: 2000,
      //       hideProgressBar: false,
      //       closeOnClick: true,
      //       pauseOnHover: true,
      //       draggable: true,
      //       progress: undefined,
      //       theme: "dark",
      //     });
      //     return;
      //   }
      //   setRecordActions((prev) => {
      //     return [...prev.slice(0, sub), ...prev.slice(sub + 1, main)];
      //   });
      //   setActionSelectIndex(-1);
      //   setPairedSelectIndex(-1);
    }
  }

  return (
    <div
      className="container.ui"
      style={{
        position: "absolute",
        width: "76%",
        height: "80%",
        top: "50%",
        padding: 0,
        left: "50%",
        backgroundColor: "white",
        borderRadius: "2px",
        zIndex: 909,
        transform: "translate(-50%, -50%)",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          alignItems: "center",
          width: "100%",
          margin: "2vh",
          position: "relative",
        }}
      >
        <span style={{ fontSize: "1rem", fontWeight: "bold" }}>宏设置</span>

        <div
          className="buttonGroup"
          style={{
            borderRadius: "5px",
            padding: "0.6vh 1.9vh",
            position: "absolute",
            right: 0,
          }}
        >
          <CommonButton
            text="取消"
            actionCallback={() => {
              dispatch(clearAllPopupAction());
            }}
            blue={false}
          />

          <CommonButton
            text="确定"
            actionCallback={() => {
              dispatch(clearAllPopupAction());
            }}
            blue={true}
          />
        </div>
      </div>
      <SeparatorLine />

      <div
        className="main_parts"
        style={{
          display: "flex",
          flex: 1,
          height: "90%",
          width: "100%",
          fontSize: "0.4rem",
          padding: 0,
          margin: 0,
        }}
      >
        <div
          className="leftpanel"
          style={{
            display: "flex",
            flex: 1,
            height: "100%",
            flexDirection: "column",

            padding: "0 2vh",
            margin: "0",
          }}
        >
          <span
            style={{
              fontSize: "0.8rem",
              marginTop: "1.1vh",
              fontWeight: "bold",
            }}
          >
            宏功能
          </span>
          <div
            style={{
              width: "100%",
              display: "flex",
              flexDirection: "column",
            }}
          >
            {defaultMacros.map((item, index) => {
              return (
                <span
                  key={item.name}
                  style={{
                    width: "100%",
                    fontSize: "0.7rem",
                    cursor: "pointer",
                    marginTop: "0.5vh",
                    padding: "1.7vh 0vh 1.7vh 1vh",
                    borderRadius: "5px",
                    backgroundColor:
                      leftMacroSelectIndex === index ? "#0042da" : "white",
                  }}
                  onClick={() => {
                    setLeftMacroSelectIndex(index);
                    setActionSelectIndex(-1);
                    setRecordActions(defaultMacros[index].actionList);
                  }}
                >
                  {item.name}
                </span>
              );
            })}
          </div>
        </div>
        <div
          className="middlepanel"
          style={{
            display: "flex",
            flex: 1,
            height: "100%",
            flexDirection: "column",
            padding: "0 2vh",

            borderLeft: "1px solid #d8d8d8",
            borderRight: "1px solid #d8d8d8",
          }}
        >
          <div
            style={{
              display: "flex",
              height: "5%",
              width: "100%",
              margin: "1.0vh 0",

              justifyContent: "space-between",
            }}
          >
            <div>
              <button
                ref={recordButtonRef}
                style={{
                  border: "none",
                  borderRadius: "5px",
                  backgroundColor: "#d9d9d9",
                  margin: "0.3vh 0.8vh",
                  padding: "0.6vh 1.9vh",
                  cursor: "pointer",
                  display: "flex",
                  alignItems: "center",
                }}
                data-type="eventTarget-Record"
                onClick={() => {
                  onActionButtonClicked("record");
                  recordButtonRef.current.blur();
                }}
              >
                <img
                  src={recording ? "/stop.png" : "/record.png"}
                  style={{
                    width: "1.8vh",
                    marginRight: "0.3vh",
                    height: "1.8vh",
                  }}
                ></img>
                <span> {recording ? "停止录制" : "开始录制"}</span>
              </button>
            </div>

            <div>
              <CommonButton
                text="清除全部"
                actionCallback={() => {
                  onActionButtonClicked("reset");
                }}
                blue={false}
              />
              <CommonButton
                text="保存"
                actionCallback={() => {
                  onActionButtonClicked("save");
                }}
                blue={true}
              />
            </div>
          </div>

          <div
            className="scrollsection"
            style={{
              display: "flex",
              height: "100%",
              maxHeight: "100%",
              margin: "0 0 0 0",
              width: "100%",
              margin: "1.1vh 0",
              padding: "0vh 0",
              flexDirection: "column",
              overflowY: "auto",
              //border: "1px solid red",
            }}
          >
            {recordActions.map((item, index) => (
              <MacroAction
                isDown={item.isDown}
                event={item.name}
                key={item.key}
                duration={item.duration}
                selected={actionSelectIndex === index}
                onClick={(action) => {
                  if (action === "select") {
                    setActionSelectIndex(index);
                  } else if (action === "trash") {
                    console.log("We get trash action");
                    setActionSelectIndex(-1);
                    setRecordActions((prev) => {
                      return [
                        ...prev.slice(0, index),
                        ...prev.slice(index + 1),
                      ];
                    });
                  }

                  //setPairedSelectIndexWrapper(index);
                }}
              /> //timeStamp
            ))}
          </div>
        </div>
        <div
          className="rightpanel"
          style={{
            display: "flex",
            flex: 1,
            height: "100%",
            flexDirection: "column",
            padding: "0 2vh",
            margin: "0",
          }}
        >
          <span
            style={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              marginTop: "1.1vh",
            }}
          >
            宏设置
          </span>

          <span
            style={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              marginTop: "1.1vh",
            }}
          >
            按键内容
          </span>

          <div
            style={{
              width: "3.7vh",
              height: "3.7vh",
              borderRadius: "5px",
              border: "1px solid #dfdfdf",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              padding: "0.2vh 5vh",
              margin: "0.5vh 0",
              backgroundColor: editKeyName ? "#0042DA" : "#FFFFFF",
              fontSize: "0.7rem",
            }}
            onClick={() => {
              setEditKeyName((prev) => {
                const editKeyName = !prev;

                if (editKeyName) {
                  window.addEventListener("mouseup", editEventFunc);
                  window.addEventListener("mousedown", editEventFunc);
                  window.addEventListener("keydown", editEventFunc);
                  window.addEventListener("keyup", editEventFunc);
                  window.addEventListener("contextmenu", editEventFunc);
                } else {
                  //   setLastRecordLength(recordActions.length);
                  window.removeEventListener("mouseup", editEventFunc);
                  window.removeEventListener("mousedown", editEventFunc);
                  window.removeEventListener("keydown", editEventFunc);
                  window.removeEventListener("keyup", editEventFunc);
                  window.removeEventListener("contextmenu", editEventFunc);
                }
                return editKeyName;
              });
            }}
          >
            {actionSelectIndex >= 0
              ? recordActions[actionSelectIndex].name
              : ""}
          </div>

          <span
            style={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              marginTop: "1.1vh",
            }}
          >
            按键类型
          </span>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor:
                actionSelectIndex >= 0 &&
                recordActions[actionSelectIndex].isDown
                  ? "#0042DA"
                  : "#d9d9d9",
              borderRadius: "5px",
              margin: "1.6vh 0 0 0",
              padding: "0",
              alignItems: "center",
            }}
            onClick={() => {
              setRecordActions((prev) => {
                if (actionSelectIndex >= 0) {
                  let newActions = JSON.parse(JSON.stringify(prev));

                  newActions[actionSelectIndex].isDown = true;
                  return newActions;
                } else {
                  return prev;
                }
              });
            }}
          >
            <img
              src={"/down-arrow.png"}
              style={{
                width: "5%",
                padding: "1.1vh 0",
                margin: "0",
              }}
            ></img>
            <button
              style={{
                border: "none",
                borderRadius: "5px",
                backgroundColor: "transparent",
                // margin: "0.3vh 1.8vh 0.3vh 0",
                // padding: "0.6vh 1.9vh",
                cursor: "pointer",
              }}
            >
              按下
            </button>
          </div>

          <div
            style={{
              display: "flex",
              justifyContent: "center",
              backgroundColor: "#d9d9d9",
              backgroundColor:
                actionSelectIndex >= 0 &&
                recordActions[actionSelectIndex].isDown
                  ? "#d9d9d9"
                  : "#0042DA",
              borderRadius: "5px",
              margin: "1.6vh 0 0 0",
              padding: "0",
              alignItems: "center",
            }}
            onClick={() => {
              setRecordActions((prev) => {
                if (actionSelectIndex >= 0) {
                  let newActions = JSON.parse(JSON.stringify(prev));

                  newActions[actionSelectIndex].isDown = false;
                  return newActions;
                } else {
                  return prev;
                }
              });
            }}
          >
            <img
              src={"/up-arrow.png"}
              style={{
                width: "5%",
                padding: "1.1vh 0",
                margin: "0",
              }}
            ></img>

            <button
              style={{
                border: "none",
                borderRadius: "5px",
                backgroundColor: "transparent",
                // margin: "0.3vh 1.8vh 0.3vh 0",
                // padding: "0.6vh 1.9vh",
                cursor: "pointer",
              }}
            >
              松开
            </button>
          </div>

          <span
            style={{
              fontSize: "0.8rem",
              fontWeight: "bold",
              marginTop: "1.1vh",
            }}
          >
            持续时间(ms)
          </span>

          <input
            style={{
              marginTop: "1vh",
              height: "1.8rem",
              backgroundColor: "#d9d9d9",
              borderRadius: "5px",
              border: "none",
              padding: "0vh  1.2vh",
              outline: "none",
            }}
            type="number"
            value={
              actionSelectIndex >= 0
                ? recordActions[actionSelectIndex].duration
                : ""
            }
            onChange={(e) => {
              console.log("This is new content", e.target.value);
              setRecordActions((prev) => {
                let newActions = JSON.parse(JSON.stringify(prev));

                newActions[actionSelectIndex].duration = e.target.value;
                return newActions;
              });
            }}
          ></input>
        </div>
      </div>
    </div>
  );
}

export default MacroConfigureUI;
function MacroAction({ duration, event, isDown, selected, onClick }) {
  const timeDuration = duration + "ms";
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "row",
        backgroundColor: selected ? "#0042da" : "#f2f4f5",
        borderRadius: "5px",
        width: "100%",
        height: "4.2vh",
        margin: "0 0 1.0vh 0",
        fontSize: "0.8rem",
        justifyContent: "space-between",
        padding: 0,
        alignItems: "center",
        cursor: "pointer",
      }}
      onClick={() => {
        onClick("select");
      }}
    >
      <img
        src="/draggable.png"
        style={{
          width: "9%",
          backgroundColor: "#D5dde5",
          margin: "0.9vh 0",
          height: "100%",
          borderTopLeftRadius: "5px",
          borderBottomLeftRadius: "5px",
        }}
      ></img>
      <img
        src={isDown ? "/down-arrow.png" : "/up-arrow.png"}
        style={{ width: "6%", padding: "0.6vh" }}
      ></img>

      <div
        style={{
          //   width: "6.7vh",
          height: "3.7vh",
          borderRadius: "5px",
          textAlign: "center",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          backgroundColor: "#FFFFFF",
        }}
      >
        <span>{event}</span>
      </div>

      <span
        style={{
          backgroundColor: "#D9E2ee",
          borderRadius: "3px",
          padding: "0.5vh 1vh",
          width: "10vh",
        }}
      >
        {timeDuration}
      </span>

      <img
        src="/broom.png"
        style={{ width: "7%", marginRight: "1vh" }}
        onClick={(e) => {
          e.stopPropagation();
          onClick("trash");
        }}
      ></img>
    </div>
  );
}
