// 联系我们组件
function SuperKeysPage({}) {
  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        flexDirection: "column",
        fontSize: "1rem",
        alignItems: "center",
        padding: "1vh 1vh",
        marginBottom: "1vh",
        backgroundColor: "white",
        borderRadius: "8px",
        //border: "1px blue solid",
        justifyContent: "flex-start",
        marginTop: "3vh",
        height: "50vh",
      }}
    >
      <h1>SuperKeysPage</h1>
      <p>SuperKeysPage</p>
    </div>
  );
}

export default SuperKeysPage;
