export const CONNECTED_DEVICE = "CONNECTED_DEVICE";
export const RESTORE_BUTTON_TAB_CONF = "RESTORE_BUTTON_TAB_CONF";
export const USER_CLICK_MENU = "USER_CLICK_MENU";
export const SET_FATAL_LOG = "SET_FATAL_LOG";
export const CHANGE_APP_LANG = "CHANGE_APP_LANG";

export function convertIntToHexString(intNum, bytes) {
  if (intNum > 255 && bytes === 1) {
    console.error(
      "Incorrect number " + intNum + " convert to " + bytes + " hex string"
    );
  }

  let result = intNum.toString(16).padStart(2 * bytes, "0");
  if (result.length % 2 !== 0) {
    console.error(
      "Incorrect number " + intNum + " convert to " + bytes + " hex string"
    );
  }

  //let ret = result.replace(/(..)/g, "$1 ");
  let bigEnd = result.trim();

  const groups = bigEnd.match(/.{2}/g); // ["12", "34", "56", "78"]
  const reversed = groups.reverse();
  return reversed.join(" ");
}
