import { useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  userClickKeyboardBelowAction,
  userClickLayerAction,
  userClickMapPendingKeyAction,
  userMouseUpPosAction,
  userSetDragMappingAction,
} from "../webhid/action";

import { useEffect } from "react";
const LayerButton = ({ selected, index, text, onClick }) => {
  return (
    <button
      style={{
        backgroundColor: selected ? "#0043dd" : "white",
        color: selected ? "#eee" : "black",
        borderRadius: "3px",
        border: "none",
        width: "100%",
        margin: "1vh 0",
        padding: "0.4vh 0",
        cursor: "pointer",
        lineHeight: "1.5",
      }}
      onClick={() => {
        onClick(index);
      }}
    >
      {text}
    </button>
  );
};

function KeyBoardMap({ topMenuIndex, singleSelect }) {
  const dispatch = useDispatch();

  const common = useSelector((state) => state.persistent.common);
  const selectLayerIndex = common.buttonMap.layerIndex;

  const [selectOption, setSelectOption] = useState(-1);

  function onLayerClick(layerIndex) {
    dispatch(userClickLayerAction(layerIndex)); //based 0
    setClickObj(null);
  }

  function selectButtonClick(buttonIndex) {
    setSelectOption(buttonIndex);
  }

  //Middle.
  let selectedKeys = common.buttonMap.mapPendingKey;

  //Bottom.
  const keyboardBelowClickValue = common.buttonMap.keyboardBelowClickValue;
  const belowUpdateFlag = common.buttonMap.belowUpdateFlag;

  let currentLayerMappingData =
    common.buttonMap.mappingData[common.buttonMap.layerIndex];

  const canvasRef = useRef(null);

  const stdWidth = 118;
  const stdHeight = 114;

  const line1Y = 82;
  const line2Y = 248;
  const line3Y = 398;
  const line4Y = 548;
  const line5Y = 699;
  const line6Y = 850;

  // 按键区域（每个键的坐标和大小）
  const keys = [
    // line 1
    { id: "Esc", x: 60, y: line1Y, width: stdWidth, height: stdHeight },
    { id: "F1", x: 245, y: line1Y, width: stdWidth, height: stdHeight },
    { id: "F2", x: 392, y: line1Y, width: stdWidth, height: stdHeight },
    { id: "F3", x: 543, y: line1Y, width: stdWidth, height: stdHeight },
    { id: "F4", x: 693, y: line1Y, width: stdWidth, height: stdHeight },
    { id: "F5", x: 874, y: line1Y, width: stdWidth, height: stdHeight },
    { id: "F6", x: 1024, y: line1Y, width: stdWidth, height: stdHeight },
    { id: "F7", x: 1171, y: line1Y, width: stdWidth, height: stdHeight },
    { id: "F8", x: 1321, y: line1Y, width: stdWidth, height: stdHeight },
    { id: "F9", x: 1505, y: line1Y, width: stdWidth, height: stdHeight },
    { id: "F10", x: 1656, y: line1Y, width: stdWidth, height: stdHeight },
    { id: "F11", x: 1805, y: line1Y, width: stdWidth, height: stdHeight },
    { id: "F12", x: 1960, y: line1Y, width: stdWidth, height: stdHeight },
    { id: "LOCK", x: 2140, y: line1Y, width: stdWidth, height: stdHeight },
    { id: "Prtscr", x: 2345, y: line1Y, width: stdWidth, height: stdHeight },
    { id: "ScrLk", x: 2495, y: line1Y, width: stdWidth, height: stdHeight },
    { id: "Prtblk", x: 2650, y: line1Y, width: stdWidth, height: stdHeight },

    // line2
    { id: "~", x: 60, y: line2Y, width: stdWidth, height: stdHeight },
    { id: "!1", x: 216, y: line2Y, width: stdWidth, height: stdHeight },
    { id: "@2", x: 362, y: line2Y, width: stdWidth, height: stdHeight },
    { id: "#3", x: 513, y: line2Y, width: stdWidth, height: stdHeight },
    { id: "$4", x: 663, y: line2Y, width: stdWidth, height: stdHeight },
    { id: "%5", x: 812, y: line2Y, width: stdWidth, height: stdHeight },
    { id: "^6", x: 962, y: line2Y, width: stdWidth, height: stdHeight },
    { id: "&7", x: 1112, y: line2Y, width: stdWidth, height: stdHeight },
    { id: "*8", x: 1257, y: line2Y, width: stdWidth + 2, height: stdHeight },
    { id: "(9", x: 1411, y: line2Y, width: stdWidth, height: stdHeight },
    { id: ")0", x: 1562, y: line2Y, width: stdWidth, height: stdHeight },
    { id: "_-", x: 1710, y: line2Y, width: stdWidth, height: stdHeight },
    { id: "+=", x: 1863, y: line2Y, width: stdWidth, height: stdHeight },
    { id: "backspace", x: 2012, y: line2Y, width: 246, height: stdHeight },
    { id: "Insert", x: 2345, y: line2Y, width: stdWidth, height: stdHeight },
    { id: "Home", x: 2495, y: line2Y, width: stdWidth, height: stdHeight },
    { id: "PgUp", x: 2650, y: line2Y, width: stdWidth, height: stdHeight },

    // line3
    { id: "Tab", x: 60, y: line3Y, width: 192, height: stdHeight },
    { id: "Q", x: 282, y: line3Y, width: stdWidth, height: stdHeight },
    { id: "W", x: 432, y: line3Y, width: stdWidth, height: stdHeight },
    { id: "E", x: 582, y: line3Y, width: stdWidth, height: stdHeight },
    { id: "R", x: 733, y: line3Y, width: stdWidth, height: stdHeight },
    { id: "T", x: 882, y: line3Y, width: stdWidth, height: stdHeight },
    { id: "Y", x: 1031, y: line3Y, width: stdWidth, height: stdHeight },
    { id: "U", x: 1182, y: line3Y, width: stdWidth, height: stdHeight },
    { id: "I", x: 1332, y: line3Y, width: stdWidth + 2, height: stdHeight },
    { id: "O", x: 1482, y: line3Y, width: stdWidth, height: stdHeight },
    { id: "P", x: 1632, y: line3Y, width: stdWidth, height: stdHeight },
    { id: "{[", x: 1782, y: line3Y, width: stdWidth, height: stdHeight },
    { id: "}]", x: 1932, y: line3Y, width: stdWidth, height: stdHeight },
    { id: "|\\", x: 2082, y: line3Y, width: 176, height: stdHeight },

    { id: "Del", x: 2345, y: line3Y, width: stdWidth, height: stdHeight },
    { id: "End", x: 2495, y: line3Y, width: stdWidth, height: stdHeight },
    { id: "PgDn", x: 2650, y: line3Y, width: stdWidth, height: stdHeight },

    // line4
    { id: "Caps Lock", x: 60, y: line4Y, width: 262, height: stdHeight },
    { id: "A", x: 357, y: line4Y, width: stdWidth, height: stdHeight },
    { id: "S", x: 506, y: line4Y, width: stdWidth, height: stdHeight },
    { id: "D", x: 655, y: line4Y, width: stdWidth, height: stdHeight },
    { id: "F", x: 804, y: line4Y, width: stdWidth, height: stdHeight },
    { id: "G", x: 953, y: line4Y, width: stdWidth, height: stdHeight },
    { id: "H", x: 1104, y: line4Y, width: stdWidth, height: stdHeight },
    { id: "J", x: 1251, y: line4Y, width: stdWidth + 2, height: stdHeight },
    { id: "K", x: 1404, y: line4Y, width: stdWidth, height: stdHeight },
    { id: "L", x: 1555, y: line4Y, width: stdWidth, height: stdHeight },
    { id: ":;", x: 1705, y: line4Y, width: stdWidth, height: stdHeight },
    { id: "\"'", x: 1857, y: line4Y, width: stdWidth, height: stdHeight },
    { id: "Enter", x: 2009, y: line4Y, width: 247, height: stdHeight },

    // line5
    { id: "ShiftL", x: 60, y: line5Y, width: 336, height: stdHeight },
    { id: "Z", x: 427, y: line5Y, width: stdWidth, height: stdHeight },
    { id: "X", x: 576, y: line5Y, width: stdWidth, height: stdHeight },
    { id: "C", x: 725, y: line5Y, width: stdWidth, height: stdHeight },
    { id: "V", x: 874, y: line5Y, width: stdWidth, height: stdHeight },
    { id: "B", x: 1025, y: line5Y, width: stdWidth, height: stdHeight },
    { id: "N", x: 1178, y: line5Y, width: stdWidth, height: stdHeight },
    { id: "M", x: 1327, y: line5Y, width: stdWidth + 2, height: stdHeight },
    { id: "<,", x: 1474, y: line5Y, width: stdWidth, height: stdHeight },
    { id: ">.", x: 1625, y: line5Y, width: stdWidth, height: stdHeight },
    { id: "?/", x: 1775, y: line5Y, width: stdWidth, height: stdHeight },
    { id: "ShiftR", x: 1927, y: line5Y, width: 330, height: stdHeight },
    { id: "Arrowup", x: 2495, y: line5Y, width: stdWidth, height: stdHeight },

    // line6
    { id: "CtrlL", x: 60, y: line6Y, width: 192, height: stdHeight },
    { id: "WinL", x: 281, y: line6Y, width: 192, height: stdHeight },
    { id: "AltL", x: 502, y: line6Y, width: 192, height: stdHeight },
    { id: "Spacebar", x: 725, y: line6Y, width: 870, height: stdHeight },
    { id: "AltR", x: 1625, y: line6Y, width: 192, height: stdHeight },
    { id: "WinR", x: 1845, y: line6Y, width: 192, height: stdHeight },
    { id: "CtrlR", x: 2066, y: line6Y, width: 192, height: stdHeight },

    { id: "ArrowL", x: 2345, y: line6Y, width: stdWidth, height: stdHeight },
    { id: "ArrowD", x: 2495, y: line6Y, width: stdWidth, height: stdHeight },
    { id: "ArrowR", x: 2650, y: line6Y, width: stdWidth, height: stdHeight },
  ];

  const canvas = canvasRef.current;
  const [clickObj, setClickObj] = useState(null);

  useEffect(() => {
    if (common.buttonMap.mouseUpX > 0 && canvas) {
      const rect = canvas.getBoundingClientRect();
      console.log("canvas rect is ", rect);
      keys.map((item, _) => {
        const canvasX =
          (common.buttonMap.mouseUpX - rect.left) * (canvas.width / rect.width);
        const canvasY =
          (common.buttonMap.mouseUpY - rect.top) *
          (canvas.height / rect.height);

        if (
          item.x < canvasX &&
          canvasX < item.x + item.width &&
          item.y < canvasY &&
          canvasY < item.y + item.height
        ) {
          //如果我最终拖动的和原来选中的想要配置的不是一样键。
          //因为我在拖动的时候，开始拖的时候就已经给配置了，但实质生效的是后面的拖动，所以前面的要revert掉。

          //但是后面的也得映射上去。
          setClickObj(item);
          let newSelectedKeys = [];
          if (singleSelect) {
            newSelectedKeys = item.id;
          } else {
            newSelectedKeys = Array.from(new Set([...selectedKeys, item.id]));
          }

          console.log(
            "We select key =====>",
            common.buttonMap.mouseUpX,
            item.id,
            selectedKeys,
            newSelectedKeys
          );
          drawKeyboard(newSelectedKeys, false); // Draw the keyboard with the updated keys

          dispatch(userSetDragMappingAction(item.id, newSelectedKeys));
        }
      });

      dispatch(userMouseUpPosAction(-800, -800));
    }
  }, [common.buttonMap.mouseUpX]);
  // on Drag up to a button.

  function drawRoundedRect(ctx, x, y, width, height, radius) {
    ctx.beginPath();
    ctx.moveTo(x + radius, y); // 从左上角的圆弧开始
    ctx.lineTo(x + width - radius, y); // 上边线
    ctx.stroke();
    ctx.quadraticCurveTo(x + width, y, x + width, y + radius); // 右上角圆弧
    ctx.lineTo(x + width, y + height - radius); // 右边线
    ctx.stroke();
    ctx.quadraticCurveTo(x + width, y + height, x + width - radius, y + height); // 右下角圆弧
    ctx.lineTo(x + radius, y + height); // 下边线
    ctx.stroke();
    ctx.quadraticCurveTo(x, y + height, x, y + height - radius); // 左下角圆弧
    ctx.lineTo(x, y + radius); // 左边线
    ctx.stroke();
    ctx.quadraticCurveTo(x, y, x + radius, y); // 左上角圆弧
    ctx.closePath(); // 闭合路径
    ctx.fill(); // 填充颜色
  }

  function drawConfiguredData(canvas, exclude) {
    // draw configured data.
    if (canvas) {
      const ctx = canvas.getContext("2d");
      for (let key in currentLayerMappingData) {
        //console.log(key, ":", currentLayerMappingData[key]);

        const item = keys.find((k) => k.id === key);
        if (item && item.id != exclude.id) {
          ctx.fillStyle = "rgba(0, 255, 0, 0.5)"; // Set fill color for the rectangle
          ctx.strokeStyle = "green";
          drawRoundedRect(ctx, item.x, item.y, item.width, item.height, 12);

          // Draw text in the center of the rectangle
          let showText = currentLayerMappingData[key].showText;
          showText = showText.split("\\n").join(" ");
          ctx.font = "2rem bold Arial"; // Set font size and family
          ctx.textAlign = "center"; // Center the text
          ctx.textBaseline = "middle"; // Middle align the text vertically
          ctx.fillStyle = "blue"; // Set text color
          ctx.fillText(
            showText,
            item.x + item.width / 2,
            item.y + item.height / 2
          );
        }
      }
    }
  }

  function drawKeyboard(newSet, isClick) {
    const canvas = canvasRef.current;
    const ctx = canvas.getContext("2d");
    ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the entire canvas

    console.log("We begin to drawKeyboard here", newSet);
    ctx.fillStyle = "rgba(255, 0, 0, 0.5)"; // 半透明绿色
    ctx.strokeStyle = "red";

    const key = keys.find((k) => k.id === newSet);
    if (key) {
      console.log("We begin to drawKeyboard red selected rectangle", newSet);
      drawRoundedRect(ctx, key.x, key.y, key.width, key.height, 12);

      // draw text.
      if (!isClick) {
        ctx.font = "2rem bold Arial"; // Set font size and family
        ctx.textAlign = "center"; // Center the text
        ctx.textBaseline = "middle"; // Middle align the text vertically
        ctx.fillStyle = "blue"; // Set text color
        ctx.fillText(
          keyboardBelowClickValue.split("\\n").join(" "),
          key.x + key.width / 2,
          key.y + key.height / 2
        );
      }
    }

    drawConfiguredData(canvas, key);
  }

  // used to convert location in browser and canvas.
  const setCanvasSizeAttribute = () => {
    const canvas = canvasRef.current;
    canvas.width = 2844;
    canvas.height = 1073;
  };

  function canvasClick(event) {
    const canvas = canvasRef.current;

    const rect = canvas.getBoundingClientRect();
    const mouseX = event.clientX;
    const mouseY = event.clientY;

    const canvasX = (mouseX - rect.left) * (canvas.width / rect.width);
    const canvasY = (mouseY - rect.top) * (canvas.height / rect.height);

    console.log("MouseX ", canvasX, " MouseY ", canvasY);

    keys.forEach((key) => {
      if (
        canvasX >= key.x &&
        canvasX <= key.x + key.width &&
        canvasY >= key.y &&
        canvasY <= key.y + key.height
      ) {
        setClickObj(key);

        // 如果已选中则取消选中，未选中则添加
        if (selectedKeys === key.id) {
          //dispatch(userClickMapPendingKeyAction("", ""));
          //drawKeyboard("key.id", true); // Draw the keyboard with the updated keys
        } else {
          dispatch(userClickMapPendingKeyAction(key.id, key.id));
          drawKeyboard(key.id, true); // Draw the keyboard with the updated keys
        }
      }
    });
  }

  useEffect(() => {
    setCanvasSizeAttribute();
    const canvas = canvasRef.current; // Get the canvas reference here
    if (canvas) {
      // Check if canvas is not null
      console.log("Have we get canvas?", canvas, currentLayerMappingData);
      drawConfiguredData(canvas, {});
    }
  }, [canvasRef, selectLayerIndex, topMenuIndex]); //currentLayerMappingData

  useEffect(() => {
    if (canvas && clickObj) {
      const ctx = canvas.getContext("2d");

      ctx.clearRect(0, 0, canvas.width, canvas.height); // Clear the entire canvas

      drawKeyboard(clickObj.id, false);
      // drawConfiguredData(canvas, click);

      ctx.font = "2rem bold Arial"; // Set font size and family
      ctx.textAlign = "center"; // Center the text
      ctx.textBaseline = "middle"; // Middle align the text vertically
      ctx.fillStyle = "blue"; // Set text color

      ctx.fillText(
        keyboardBelowClickValue.split("\\n").join(" "),
        clickObj.x + clickObj.width / 2,
        clickObj.y + clickObj.height / 2
      );
    }
  }, [keyboardBelowClickValue, belowUpdateFlag]);

  const layerNames = ["主层(WIN)", "FN1", "FN2(MAC)", "FN3"];

  const selectNames = ["全选", "反选", "取消全选"];
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        height: "40vh",
        justifyContent: "center",
        marginTop: "3vh",
      }}
    >
      <div
        className="layers"
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          marginRight: "2vh",
          width: "8%",
        }}
      >
        {layerNames.map((item, index) => (
          <LayerButton
            key={index}
            index={index}
            selected={selectLayerIndex === index}
            onClick={onLayerClick}
            text={item}
          ></LayerButton>
        ))}
      </div>
      <div
        className="keyboard_image"
        style={{
          position: "relative",
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "70%",
          height: "100%",
        }}
      >
        <canvas
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          draggable={false}
          onClick={canvasClick}
          ref={canvasRef}
        ></canvas>
        <img
          src="/keyboard.png"
          draggable={false}
          style={{ width: "100%", height: "100%" }}
        ></img>
      </div>

      <div
        className="select_menu"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "8%",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: "2vh",
          //   border: "3px solid black",
        }}
      >
        {selectNames.map((item, index) => (
          <SelectButton
            icon="/check-mark.png"
            text={item}
            selected={selectOption === index}
            index={index}
            key={index}
            onClick={selectButtonClick}
          />
        ))}
      </div>
    </div>
  );
}

export default KeyBoardMap;

const SelectButton = ({ selected, index, text, onClick, icon }) => {
  return (
    <div
      style={{
        display: "flex",
        width: "100%",
        backgroundColor: selected ? "gray" : "white",
        alignItems: "center",
        margin: "1vh 0",
        borderRadius: "3px",
        cursor: "pointer",
      }}
      onClick={() => {
        onClick(index);
      }}
    >
      <img
        src={icon}
        style={{ width: "2vh", height: "2vh", marginRight: "1vh" }}
      ></img>
      <div
        style={{
          border: "none",
          width: "100%",
          padding: "0.4vh 0",
          lineHeight: "1.5",
        }}
      >
        {text}
      </div>
    </div>
  );
};
