import { getPredefinedKeyByName } from "../keymap/SystemKeyMap";
import { getCommandByName, getSpecialKey } from "../keymap/ConcreteKeymap";
import { convertIntToHexString } from "../webhid/constants";
import SendDataToHID from "./sendData";

// console.log = function () {};
// console.warn = function () {};
const initialState = {
  macroNextStoreIndex: 0, // storeIndex base number.
  //macro list
  //   macro = {
  //     name: name,
  //     storeIndex: above_macroIndex
  //     hidIndex: sequence stored in hid devices.
  //     actionList: recordActions,
  //   };

  //   let action = {
  //     text1: eventName,
  //     timeStamp: event.timeStamp,
  //     duration: (duration / 1000.0).toFixed(2),
  //   };
  macroLists: [],
  macroSettings: [false, false, false, false],
};

function macroReducer(state = initialState, action) {
  // console.log(
  //   "We macroReducer get action ",
  //   action,
  //   "our previous state is",
  //   state
  // );

  switch (action.type) {
    case "MACRO_OPTION_CLICKED":
      let currentconfig = [...state.macroSettings];

      currentconfig.forEach((_, index) => {
        currentconfig[index] = false;
      });
      currentconfig[action.index] = action.checked;

      let result = { ...state, macroSettings: currentconfig };

      return result;

    case "MACRO_ADDED":
      return {
        ...state,
        macroLists: [...state.macroLists, action.payload],
        macroNextStoreIndex: state.macroNextStoreIndex + 1,
      };

    case "MACRO_DELETED":
      let exists = JSON.parse(JSON.stringify(state));
      exists.macroLists.splice(action.index, 1);
      return exists;

    case "MACRO_REPLACED":
      //TODO only persistent macroLists part.
      let exists1 = JSON.parse(JSON.stringify(state));
      exists1.macroLists[action.index] = action.payload;
      console.log("After replace, it's content is", exists1);

      // ROCK
      //writeMacroDataToHID(exists1.macroLists);
      return exists1;

    case "RESTORE_ALL_CONFIGS":
      return initialState;

    default:
      return state;
  }
}

function writeMacroDataToHID(macroLists1) {
  let commandSequence = [];
  macroLists1.forEach((item, index) => {
    if (item.hidIndex >= 0) {
      let offset = 0;
      let command = "4A" + " " + convertIntToHexString(item.hidIndex, 1);

      const macroLength = item.actionList.length * 5;

      let macroData = "";
      //当一个宏被清空了，之前是有数据的（有配置及映射的），此时数据没有写进去？ 导致点位的hid 序号一直是有宏实质内容的。
      item.actionList.forEach((action, index1) => {
        if (index1 % 11 == 0) {
          macroData = "";
        }

        offset = Math.floor(index1 / 11) * 11 * 5;

        let code = getEncodedKeyByActionDesc(action.text1, action.isUp);

        if (code === undefined) {
          console.error("We get incorrect code " + action.text1);
          code = "ff ff ff";
        }

        macroData += " " + code;
        macroData += " " + convertIntToHexString(action.duration * 1000, 2);

        if (index1 % 11 === 10 || index1 === item.actionList.length - 1) {
          // send data
          let allSend = command;
          allSend += " " + offset.toString(16).padStart(2, "0");
          allSend += " " + convertIntToHexString(macroLength, 1);
          allSend += macroData;

          console.log("We will send command " + allSend);

          SendDataToHID(allSend);

          commandSequence.push(allSend);
        }
      });
    }
  });
}

export function getEncodedKeyByActionDesc(desc, isUp) {
  let result = getPredefinedKeyByName(desc);
  if (result === undefined) {
    let parts = desc.split("-");
    if (parts.length <= 1) return undefined;

    let specialKey = getSpecialKey(desc);
    if (specialKey == undefined) {
      let keyResult = getCommandByName(parts[1]);

      if (!isUp) {
        let l = 0x80 | parseInt(keyResult, 16);
        keyResult = convertIntToHexString(l, 1);
      }

      if (keyResult !== undefined) {
        result = "00 " + keyResult + " 00";
      }
    } else {
      result = specialKey + isUp ? " 00" : " 80";
    }
  }

  return result;
}

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

export default macroReducer;
