import "../i18n.js";
import React, { useState, useEffect, useCallback } from "react";
import "../App.css";

import { useDispatch, useSelector } from "react-redux";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useTranslation } from "react-i18next";

import TopMenuBar from "../components/TopMenuBar.js";
import { Outlet } from "react-router-dom";
import KeyBoardMap from "../components/KeyBoardMap.js";
import {
  clearAllPopupAction,
  macroConfigureUIAction,
  macroEmptyHintsAction,
  userMouseUpPosAction,
  userStopDragAction,
} from "../webhid/action.js";
import SeparatorLine from "../components/SeparatorLine.js";
import CommonButton from "../components/CommonButton.js";
import MacroConfigureUI from "../components/MacroConfigureUI.js";

function MainStructure() {
  const { t } = useTranslation();

  const common = useSelector((state) => state.persistent.common);
  const isDragging = common.buttonMap.dragFlag;
  const dispatch = useDispatch();

  const showMask = common.macroMap.mask;
  const hintText = common.macroMap.hintText;
  const showConfigureUI = common.macroMap.showConfigureUI;
  //console.log("Mainstructure showMask " + showMask + " hintText " + hintText);

  const [dragPosition, setDragPosition] = useState({ x: 0, y: 0 }); // 虚拟控件的位置

  // 鼠标移动
  const handleMouseMove = useCallback((e) => {
    if (isDragging) {
      setDragPosition({ x: e.clientX, y: e.clientY });
    }
  });

  // 鼠标松开
  const handleMouseUp = useCallback((e) => {
    if (isDragging) {
      setDragPosition({ x: -800, y: -800 });
      dispatch(userStopDragAction());
      //update mouseUpX and mouseUpY, will cause the
      dispatch(userMouseUpPosAction(e.clientX, e.clientY));
    }
  });

  return (
    <div
      onMouseUp={handleMouseUp}
      onMouseMove={handleMouseMove}
      className="main-content"
      style={{
        whiteSpace: "nowrap",
        display: "flex",
        flexDirection: "column",
        padding: "3vh 4vh",
        width: "100%",
        margin: 0,
        flex: 1,
        justifyContent: "flex-start",
        backgroundColor: "#f2f4f5",
      }}
    >
      {isDragging && (
        <div
          style={{
            position: "absolute",
            width: "3.5vh",
            height: "3.5vh",
            top: dragPosition.y,
            left: dragPosition.x,
            backgroundColor: "blue",
            borderRadius: "50%",
            zIndex: 999,
            transform: "translate(-50%, -50%)",
          }}
        ></div>
      )}

      {/* mask. */}
      {showMask && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            backgroundColor: "#00000090",
            borderRadius: "2px",
            zIndex: 809,
          }}
        ></div>
      )}

      {showConfigureUI && <MacroConfigureUI />}

      {hintText.length > 0 && (
        <div
          style={{
            position: "absolute",
            width: "18%",
            height: "22%",
            top: "50%",
            left: "50%",
            backgroundColor: "white",
            borderRadius: "2px",
            zIndex: 999,
            transform: "translate(-50%, -50%)",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-evenly",
            alignItems: "center",
          }}
        >
          <img src="/check-mark.png" style={{ width: "3vh", height: "3vh" }} />
          <span style={{ fontSize: "1rem" }}>提示</span>
          <span style={{ fontSize: "0.7rem" }}>{hintText}</span>
          <div
            style={{
              backgroundColor: "blue",
              borderRadius: "5px",
              padding: "0.4vh 1.1vh",
            }}
            onClick={() => {
              dispatch(macroConfigureUIAction(true));
            }}
          >
            设置
          </div>
        </div>
      )}

      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        //draggable
        pauseOnHover
        theme="light"
      />

      <TopMenuBar />
      <KeyBoardMap topMenuIndex={0} singleSelect={true} />
      <Outlet />
    </div>
  );
}

export default MainStructure;
