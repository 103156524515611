import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { userClickTopMenuAction } from "../webhid/action";
import { useDispatch } from "react-redux";

function TopMenuBar({ selectIdx }) {
  const menuItem = ["按键定义", "灯效设置", "快速触发", "超级按键", "其他设置"];
  const pathNav = [
    "/index/keymap",
    "/index/light",
    "/index/fasttrigger",
    "/index/superkey",
    "/index/settings",
  ];

  const dispatch = useDispatch();

  const [selectMenu, setSelectMenu] = useState(0);
  const navigate = useNavigate();
  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        fontSize: "1rem",
        alignItems: "center",
        padding: "1vh 0",
        marginBottom: "1vh",
        backgroundColor: "white",
        borderRadius: "8px",
        // border: "1px #0043dd solid",
        justifyContent: "space-around",
      }}
    >
      {menuItem.map((item, index) => (
        <div
          style={{
            display: "flex",
            backgroundColor: selectMenu === index ? "#0043dd" : "white",
            color: selectMenu === index ? "#eee" : "black",
            alignItems: "center",
            cursor: "pointer",
            //backgroundColor: "white",
            borderRadius: "5px",
            // border: "1px blue solid",
            padding: "0.6vh 2vh",
            justifyContent: "space-around",
          }}
          key={item}
          onClick={() => {
            setSelectMenu(index);
            dispatch(userClickTopMenuAction(index));
            navigate(pathNav[index]);
          }}
        >
          <img
            src="/check-mark.png"
            style={{
              width: "3vh",
              height: "3vh",
              marginRight: "1vh",
            }}
          ></img>
          <span>{item}</span>
        </div>
      ))}
    </div>
  );
}

export default TopMenuBar;
