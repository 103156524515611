import { useState } from "react";
import { Outlet } from "react-router-dom";

// 在组件中使用
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import { fetchData } from "./server/RequestDeviceInfo";
import { connectDeviceAction, userSelectNewDevice } from "./webhid/action";

function DeviceListPage() {
  const [selectDevice, setSelectDevice] = useState(false);

  const common = useSelector((state) => state.persistent.common);
  const connect = useSelector((state) => state.connect);

  const dispatch = useDispatch();

  const {
    data = [],
    loading = false,
    error = null,
  } = useSelector((state) => state.persistent.deviceData || {});

  //console.log("We get data", data, loading, error);
  return (
    <div
      className="device-container"
      style={{
        display: "flex",
        position: "relative",
        //border: "1px red solid",
        justifyContent: "flex-start",
        alignItems: "flex-start",
        alignContent: "flex-start",
        backgroundColor: "#f2f4f5",
        width: "100%",
        padding: "3vh 4vh",
        overflow: "auto",
        flexWrap: "wrap",
        rowGap: "1vh",
        gap: "1vh",
        margin: 0,
        flex: 1,
      }}
      onClick={() => {
        setSelectDevice(false);
      }}
    >
      {selectDevice && (
        <div
          style={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: "50%",
            height: "60%",
            padding: "1vh",
            backgroundColor: "#035623",
            maxHeight: "100%",
            overflow: "auto",
            backgroundColor: "#fff",
            alignItems: "center",
            justifyContent: "space-evenly",
            display: "flex",
            flexDirection: "column",
            boxShadow: "0 8px 16px rgba(0, 0, 0, 0.2)", // Increased shadow size
          }}
        >
          {loading && <div>Loading...</div>}
          {/* Fixed placement of loading indicator */}
          {error && <div>Error: {error}</div>}
          {/* Fixed placement of error message */}

          <>
            {data &&
              data.map((item) => (
                <div
                  key={item.type}
                  style={{
                    display: "flex",
                    justifyContent: "space-evenly",
                    alignItems: "center",
                    width: "80%",
                    margin: "2vh 1vh",
                    border: "1px gray solid",
                    height: "5vh",
                  }}
                  onClick={() => {
                    dispatch(userSelectNewDevice(item.type, item.picture));
                    setSelectDevice(false);
                  }}
                >
                  <img style={{ height: "100%" }} src={item.picture}></img>
                  <span>{item.type}</span>
                </div>
              ))}
          </>
        </div>
      )}
      {common.deviceList.map((item, index) => (
        <DeviceBlock
          key={index}
          icon={item.picture}
          text={item.type}
          onClick={() => {
            //setSelectDevice(false);
            //connect device.
            console.log("We are connect.....");
            dispatch(connectDeviceAction(0x369b, 0x00a0));
          }}
        />
      ))}

      <NewBlock
        icon="/plus.png"
        text="新增设备"
        onClick={(e) => {
          setSelectDevice(true);
          dispatch(fetchData());
          e.stopPropagation();
        }}
      />
    </div>
  );
}

export default DeviceListPage;

function DeviceBlock({ icon, text, onClick }) {
  return (
    <div
      style={{
        backgroundColor: "white",
        borderRadius: "5px",
        width: "15vw",
        height: "7vw",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: "3vh",
      }}
      onClick={() => {
        onClick();
      }}
    >
      <img
        src={icon}
        style={{
          flex: 1,
          width: "60%",
          // height: "100%",
          marginBottom: "1vh",
          objectFit: "contain",
        }}
      ></img>
      <span> {text}</span>
    </div>
  );
}

function NewBlock({ icon, text, onClick }) {
  return (
    <div
      style={{
        backgroundColor: "white",
        width: "15vw",
        borderRadius: "5px",
        height: "7vw",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.2)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        flexDirection: "column",
        margin: "3vh",
      }}
      onClick={(e) => {
        onClick(e);
      }}
    >
      <img src={icon} style={{ width: "12%", marginBottom: "2vh" }}></img>
      <span> {text}</span>
    </div>
  );
}
