import { useState, useEffect, useCallback, useRef } from "react";
import TabNavigation from "./components/TabNavigation";
import ButtonUnit from "./components/ButtonUnit";
import StatusBar from "./StatusBar";
import { useDispatch, useSelector } from "react-redux";
import {
  clearAllPopupAction,
  macroEmptyHintsAction,
  sendFatalLogAction,
  userClickKeyboardBelowAction,
  userStartDragAction,
} from "./webhid/action";
import getConnectedDevice from "./webhid/devices";
import SendDataToHID from "./webhid/sendData";
import ImageUnit from "./components/ImageUnit";
import MacroNameUnit from "./components/MacroNameUnit";

// 联系我们组件
function KeyMapsPage() {
  const tabNavData = ["基础键", "多媒体键", "灯效键", "组合键", "鼠标键", "宏"];
  const [activeTab, setActiveTab] = useState(0);
  const common = useSelector((state) => state.persistent.common);

  const dispatch = useDispatch();
  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        flexDirection: "column",
        fontSize: "1rem",
        alignItems: "center",
        padding: "1vh 1vh",
        marginBottom: "1vh",
        backgroundColor: "white",
        borderRadius: "8px",
        //border: "1px blue solid",
        justifyContent: "flex-start",
        marginTop: "3vh",
        height: "50vh",
      }}
    >
      <TabNavigation
        data={tabNavData}
        onClickFunc={(index) => {
          setActiveTab(index);
        }}
        activeTab={activeTab}
      />
      {/* Basic Keys. */}
      {activeTab === 0 && <KeyBoardComponent />}
      {/* Multimedia Keys. */}
      {activeTab === 1 && <MediaKeyList type="media" />}
      {activeTab === 2 && <MediaKeyList type="light" />}
      {activeTab === 3 && <div>compose button</div>}
      {activeTab === 4 && <MediaKeyList type="mouse" />}
      {activeTab === 5 && <MacroList />}
    </div>
  );
}

export default KeyMapsPage;

function MacroList({}) {
  const common = useSelector((state) => state.persistent.common);

  const selectedKeyValue = common.buttonMap.keyboardBelowClickValue;
  const dispatch = useDispatch();

  const macroList = [
    [
      { text: "M1", uid: "0", keycode: "XX" },
      { text: "M2", uid: "0", keycode: "XX" },
      { text: "M3", uid: "0", keycode: "XX" },
      { text: "M4", uid: "0", keycode: "XX" },
      { text: "M5", uid: "0", keycode: "XX" },
      { text: "M6", uid: "0", keycode: "XX" },
      { text: "M7", uid: "0", keycode: "XX" },
      { text: "M8", uid: "0", keycode: "XX" },
      { text: "M10", uid: "0", keycode: "XX" },
      { text: "M11", uid: "0", keycode: "XX" },
    ],
  ];

  return (
    <div
      style={{
        marginTop: "1vh",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {macroList.map((line, lineNo) => (
        <div
          key={`line-${lineNo}`}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {line.map((item, index) => {
            return (
              <MacroNameUnit
                width={2}
                height={2}
                text={item.text}
                uid={item.uid}
                key={index}
                selected={selectedKeyValue === item.text}
                onClickEvent={(clicked) => {
                  console.log("We save key number is ", lineNo * 100 + index);
                  if (clicked) {
                    dispatch(userClickKeyboardBelowAction(item.text, item.uid));

                    dispatch(
                      macroEmptyHintsAction("Macro is empty, please setup one.")
                    );
                  }
                }}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
}

function MediaKeyList({ type }) {
  const common = useSelector((state) => state.persistent.common);

  const selectedKeyValue = common.buttonMap.keyboardBelowClickValue;
  const dispatch = useDispatch();
  const multiMediaKeyList = [
    [
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
    ],
    [
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
    ],
  ];

  const lightnessKeyList = [
    [
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
    ],
  ];

  const mouseButtonList = [
    [
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
    ],
  ];

  const macroList = [
    [
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
      { icon: "/save.png", uid: "0", keycode: "XX" },
    ],
  ];
  function getKeyListByType(type) {
    if (type === "media") {
      return multiMediaKeyList;
    } else if (type === "light") {
      return lightnessKeyList;
    } else if (type === "mouse") {
      return mouseButtonList;
    } else if (type === "macro") {
      return macroList;
    }
  }

  return (
    <div
      style={{
        marginTop: "1vh",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "column",
      }}
    >
      {getKeyListByType(type).map((line, lineNo) => (
        <div
          key={`line-${lineNo}`}
          style={{ display: "flex", justifyContent: "center" }}
        >
          {line.map((item, index) => {
            return (
              <ImageUnit
                width={2}
                height={2}
                icon={item.icon}
                uid={item.uid}
                key={index}
                selected={selectedKeyValue === item.icon}
                onClickEvent={(clicked) => {
                  console.log("We save key number is ", lineNo * 100 + index);
                  if (clicked) {
                    dispatch(userClickKeyboardBelowAction(item.icon, item.uid));
                  }
                }}
              />
            );
          })}
        </div>
      ))}
    </div>
  );
}

function KeyBoardComponent() {
  const common = useSelector((state) => state.persistent.common);

  const selectedKeyValue = common.buttonMap.keyboardBelowClickValue;

  const nextWidthRef = useRef(1);

  const dispatch = useDispatch();

  nextWidthRef.current = 1;

  const keyMapData = [
    [
      { text: "Esc", uid: 0x29, keycode: "Escape" },
      { x: 1 },
      { text: "F1", uid: 0x3a, keycode: "F1" },
      { text: "F2", uid: 0x3b, keycode: "F2" },
      { text: "F3", uid: 0x3c, keycode: "F3" },
      { text: "F4", uid: 0x3d, keycode: "F4" },
      { x: 0.5 },
      { text: "F5", uid: 0x3e, keycode: "F5" },
      { text: "F6", uid: 0x3f, keycode: "F6" },
      { text: "F7", uid: 0x40, keycode: "F7" },
      { text: "F8", uid: 0x41, keycode: "F8" },
      { x: 0.5 },
      { text: "F9", uid: 0x42, keycode: "F9" },
      { text: "F10", uid: 0x43, keycode: "F10" },
      { text: "F11", uid: 0x44, keycode: "F11" }, // noaction
      { text: "F12", uid: 0x45, keycode: "F12" },
      { x: 0.25 },
      { text: "Print\\nScreen", uid: 0x46, keycode: "NoTrigger" },
      { text: "Scroll\\nLock", uid: 0x47, keycode: "ScrollLock" },
      { text: "Pause\\nBreak", uid: 0x48, keycode: "Pause" },
    ],
    [{ x: 800 }], //separator
    [
      { text: "~\\n`", uid: 0x32, keycode: "Backquote" }, //ROCK
      { text: "!\\n1", uid: 0x1e, keycode: "Digit1" },
      { text: "@\\n2", uid: 0x1f, keycode: "Digit2" },
      { text: "#\\n3", uid: 0x20, keycode: "Digit3" },
      { text: "$\\n4", uid: 0x21, keycode: "Digit4" },
      { text: "%\\n5", uid: 0x22, keycode: "Digit5" },
      { text: "^\\n6", uid: 0x23, keycode: "Digit6" },
      { text: "&\\n7", uid: 0x24, keycode: "Digit7" },
      { text: "*\\n8", uid: 0x25, keycode: "Digit8" },
      { text: "(\\n9", uid: 0x26, keycode: "Digit9" },
      { text: ")\\n0", uid: 0x27, keycode: "Digit0" },
      { text: "_\\n-", uid: 0x2d, keycode: "Minus" },
      { text: "+\\n=", uid: 0x2e, keycode: "Equal" },
      { w: 2.18 },
      { text: "Backspace", uid: 0x2a, keycode: "Backspace" },
      { x: 0.25 },
      { text: "Insert", uid: 0x49, keycode: "Insert" },
      { text: "Home", uid: 0x4a, keycode: "Home" },
      { text: "PgUp", uid: 0x4b, keycode: "PageUp" },
      { x: 0.25 },
      { text: "Num\\nLock", uid: 0x53, keycode: "NumLock" },
      { text: "/", uid: 0x54, keycode: "NumpadDivide" },
      { text: "*", uid: 0x55, keycode: "NumpadMultiply" },
      { text: "-", uid: 0x56, keycode: "NumpadSubtract" },
    ],
    [
      { w: 1.5 },
      { text: "Tab", uid: 0x2b, keycode: "Tab" },
      { text: "Q", uid: 0x14, keycode: "KeyQ" },
      { text: "W", uid: 0x1a, keycode: "KeyW" },
      { text: "E", uid: 0x08, keycode: "KeyE" },
      { text: "R", uid: 0x15, keycode: "KeyR" },
      { text: "T", uid: 0x17, keycode: "KeyT" },
      { text: "Y", uid: 0x1c, keycode: "KeyY" },
      { text: "U", uid: 0x18, keycode: "KeyU" },
      { text: "I", uid: 0x0c, keycode: "KeyI" },
      { text: "O", uid: 0x12, keycode: "KeyO" },
      { text: "P", uid: 0x13, keycode: "KeyP" },
      { text: "{\\n[", uid: 0x2f, keycode: "BracketLeft" },
      { text: "}\\n]", uid: 0x30, keycode: "BracketRight" },
      // {text:"|\\n\\", uid:0x31, keycode:"Backslash"},
      { w: 1.5 },
      { x: 1.75 },
      { x: 0.25 },
      { w: 1 },
      { text: "Delete", uid: 0x4c, keycode: "Delete" },
      { text: "End", uid: 0x4d, keycode: "End" },
      { text: "PgDn", uid: 0x4e, keycode: "PageDown" },
      { x: 0.25 },
      { text: "7\\nHome", uid: 0x5f, keycode: "Numpad7" },
      { text: "8\\n↑", uid: 0x60, keycode: "Numpad8" },
      { text: "9\\nPgUp", uid: 0x61, keycode: "Numpad9" },
      { h: 2 },
      { text: "+", uid: 0x57, keycode: "NumpadAdd" },
    ],
    [
      { w: 1.75 },
      { text: "Caps Lock", uid: 0x39, keycode: "CapsLock" },
      { text: "A", uid: 0x04, keycode: "KeyA" },
      { text: "S", uid: 0x16, keycode: "KeyS" },
      { text: "D", uid: 0x07, keycode: "KeyD" },
      { text: "F", uid: 0x09, keycode: "KeyF" },
      { text: "G", uid: 0x0a, keycode: "KeyG" },
      { text: "H", uid: 0x0b, keycode: "KeyH" },
      { text: "J", uid: 0x0d, keycode: "KeyJ" },
      { text: "K", uid: 0x0e, keycode: "KeyK" },
      { text: "L", uid: 0x0f, keycode: "KeyL" },
      { text: ":\\n;", uid: 0x33, keycode: "Semicolon" },
      { text: "\"\\n'", uid: 0x34, keycode: "Quote" },
      { w: 2.62 },
      { text: "Enter", uid: 0x28, keycode: "Enter" },
      { x: 4.2 },
      { text: "4\\n←", uid: 0x5c, keycode: "Numpad4" },
      { text: "5", uid: 0x5d, keycode: "Numpad5" },
      { text: "6\\n→", uid: 0x5e, keycode: "Numpad6" },
    ],
    [
      { w: 2.35 },
      { text: "Shift", uid: 0xe1, keycode: "ShiftLeft" },
      { text: "Z", uid: 0x1d, keycode: "KeyZ" },
      { text: "X", uid: 0x1b, keycode: "KeyX" },
      { text: "C", uid: 0x06, keycode: "KeyC" },
      { text: "V", uid: 0x19, keycode: "KeyV" },
      { text: "B", uid: 0x05, keycode: "KeyB" },
      { text: "N", uid: 0x11, keycode: "KeyN" },
      { text: "M", uid: 0x10, keycode: "KeyM" },
      { text: "<\\n,", uid: 0x36, keycode: "Comma" },
      { text: ">\\n.", uid: 0x37, keycode: "Period" },
      { text: "?\\n/", uid: 0x38, keycode: "Slash" },
      { w: 3.15 },
      { text: "Shift", uid: 0xe5, keycode: "ShiftRight" },
      { x: 1.43 },
      { text: "↑", uid: 0x52, keycode: "ArrowUp" },
      { x: 1.35 },
      { text: "1\\nEnd", uid: 0x59, keycode: "Numpad1" },
      { text: "2\\n↓", uid: 0x5a, keycode: "Numpad2" },
      { text: "3\\nPgDn", uid: 0x5b, keycode: "Numpad3" },
      { h: 2 },
      { text: "Enter", uid: 0x58, keycode: "NumpadEnter" },
    ],
    [
      { w: 1.4 },
      { text: "Ctrl", uid: 0xe0, keycode: "ControlLeft" },
      { w: 1.4 },
      { text: "Win", uid: 0xe3, keycode: "WinLeft" }, // no action.
      { w: 1.4 },
      { text: "Alt", uid: 0xe2, keycode: "AltLeft" },
      { a: 7, w: 8 },
      { text: "Space", uid: 0xcd, keycode: "Space" },
      { a: 4, w: 1.35 },
      { text: "Alt", uid: 0xe6, keycode: "AltRight" },
      { w: 1.34 },
      { text: "Win", uid: 0xe7, keycode: "WinRight" }, // noaction.

      { w: 1.4 },
      { text: "Ctrl", uid: 0xe4, keycode: "ControlRight" },
      { x: 0.25 },
      { text: "←", uid: 0x50, keycode: "ArrowLeft" },
      { text: "↓", uid: 0x51, keycode: "ArrowDown" }, //▼ ▲ ◀ ▶
      { text: "→", uid: 0x4f, keycode: "ArrowRight" },
      { x: 0.25 },
      { w: 2.1 },
      { text: "0\\nIns", uid: 0x62, keycode: "Numpad0" },
      { text: ".\\nDel", uid: 0x63, keycode: "NumpadDecimal" },
    ],
  ];

  function getShowTextByKeyCode(code) {
    for (let i = 0; i < keyMapData.length; i++) {
      for (let j = 0; j < keyMapData[i].length; j++) {
        if (keyMapData[i][j].keycode === code) {
          return keyMapData[i][j].text;
        }
      }
    }
  }

  function getUidByKeyCode(code) {
    for (let i = 0; i < keyMapData.length; i++) {
      for (let j = 0; j < keyMapData[i].length; j++) {
        if (keyMapData[i][j].keycode === code) {
          return keyMapData[i][j].uid;
        }
      }
    }
  }

  const recordEventFunc = useCallback((event) => {
    if (event instanceof KeyboardEvent) {
      //event.preventDefault();
      console.log("KeyPress ", event.key, event.code, event); //use Code.

      let showText = getShowTextByKeyCode(event.code);
      let uid = getUidByKeyCode(event.code);
      //convert event.code to show content.
      dispatch(userClickKeyboardBelowAction(showText, uid));
      dispatch(sendFatalLogAction(`key: ${event.key}, code: ${event.code}`));

      //
      if (event.code === "Escape") {
        dispatch(clearAllPopupAction());
      }
      return;
    }
  }, []);

  useEffect(() => {
    //query firmware version.
    console.log("Add keydown listener");

    window.addEventListener("keydown", recordEventFunc);
    return () => {
      window.removeEventListener("keydown", recordEventFunc);
    };
  }, []);
  return (
    <div style={{ marginTop: "1vh", display: "flex", flexDirection: "column" }}>
      {keyMapData.map((line, lineNo) => (
        <div key={`line-${lineNo}`} style={{ display: "flex" }}>
          {line.map((item, index) => {
            if (item.text) {
              const next = nextWidthRef.current;
              nextWidthRef.current = 1;
              return (
                <ButtonUnit
                  width={next}
                  height={1}
                  uid={item.uid}
                  key={`${lineNo}-${index}-${item.text}`}
                  text={item.text}
                  // text={"" + item.uid}
                  selected={selectedKeyValue === item.text}
                  onClickEvent={(clicked) => {
                    console.log("We save key number is ", lineNo * 100 + index);
                    if (clicked) {
                      dispatch(
                        userClickKeyboardBelowAction(item.text, item.uid)
                      );
                    }
                  }}
                />
              );
            } else {
              if (item.x) {
                return (
                  <ButtonUnit
                    width={item.x}
                    height={1}
                    key={`${lineNo}-${index}-${item.x}`}
                    text=""
                    selected={false}
                    onClickEvent={() => {}}
                  />
                );
              }

              if (item.w) {
                nextWidthRef.current = item.w;
              } else {
                nextWidthRef.current = 1;
              }

              return <div key={`empty-${lineNo}-${index}`}></div>;
            }
          })}
        </div>
      ))}
    </div>
  );
}
