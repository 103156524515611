import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  userMouseUpPosAction,
  userStartDragAction,
  userStopDragAction,
} from "../webhid/action";

function MacroNameUnit({ width, height, text, uid, selected, onClickEvent }) {
  const newW = 2 * width;
  const newH = 2 * height;

  const common = useSelector((state) => state.persistent.common);

  const dispatch = useDispatch();

  // 鼠标按下
  const handleMouseDown = useCallback((e) => {
    dispatch(userMouseUpPosAction(-800, -800));
    console.log("handleMouseDown --> We begin to drag?", text, "uid", uid);
    dispatch(userStartDragAction(text, uid));
  });

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseUp={(e) => {
        onClickEvent(true);
        dispatch(userStopDragAction());
      }}
      style={{
        width: `${newW}vh`,
        height: `${newH}vh`,
        borderRadius: "4px",
        display: "flex",
        margin: "0.5vh",
        flexDirection: "column",
        fontSize: "0.8rem",
        padding: "2px",
        border: selected ? "1px solid #0045d5" : "1px solid #e1e1e1",
        justifyContent: "center",
        alignItems: "center",
      }}
      onClick={(e) => {
        console.log("Button clicked");
        onClickEvent(!selected);
      }}
    >
      {text}
    </div>
  );
}

export default MacroNameUnit;
