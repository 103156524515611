import { useTranslation } from "react-i18next";
import { useState, useEffect } from "react";
import "./Sidebar.css";
import { useSelector, useDispatch } from "react-redux";
import getConnectedDevice from "./webhid/devices";
import { sendFatalLogAction, userClickMenuAction } from "./webhid/action";
import { useNavigate } from "react-router-dom";
import { connectedDeviceAction } from "./webhid/action";
import SeparatorLine from "./components/SeparatorLine";

const Sidebar = () => {
  const { t, i18n } = useTranslation();
  const common = useSelector((state) => state.persistent.common);
  const connect = useSelector((state) => state.connect);
  let isConnected = connect.isConnected;

  const productName = connect.productName;

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const connectedDevices = connect.connectedDevices;

  useEffect(() => {
    if (connect.isConnected) {
      // 定义设备监控逻辑
      let i = 0;
      const monitorDevices = async () => {
        const device = await getConnectedDevice();

        if (device === null) {
          //dispatch(connectedDeviceAction(false, null));
        }
      };

      // 定时检查设备连接状态
      const interval = setInterval(() => {
        monitorDevices();
      }, 1000);

      // 清理定时器
      return () => {
        clearInterval(interval);
      };
    } else {
      navigate("/");
      setMenuSelected(1);
    }
  }, [connect.isConnected]);

  const menuItems = ["我的设备", "工具", "固件"];
  const configItems = ["配置文件", "输入配置", "快速配置", "竞速配置"];

  const [menuSelected, setMenuSelected] = useState(-1);

  if (productName && productName.length > 0) {
    return (
      <div
        className="sidebar"
        style={{
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <img
          style={{
            width: "20%",
            maxWidth: "80%",
            alignSelf: "center",
            marginTop: "1vh",
            cursor: "pointer",
          }}
          src="/favicon.webp"
          alt="IQUNIX.store"
          onClick={() => {
            // window.open("https://iqunix.com", "_blank");
          }}
        />

        {/* middle part. */}
        <div style={{ display: "flex", flex: 1, flexDirection: "column" }}>
          <div
            style={{
              display: "flex",
              flex: 1,
              flexDirection: "column",
              lineHeight: 1.5,
            }}
          >
            {connectedDevices.map((item, index) => (
              //
              <div
                style={{
                  width: "80%",
                  display: "flex",
                  color: "black",
                  flexDirection: "row",
                  justifyContent: "center",

                  borderRadius: "4px",
                  alignItems: "center",
                  cursor: "pointer",
                  width: "100%",
                  padding: "0.5vh 0",
                  backgroundColor: item === productName ? "#ced8f8" : "#fefefe",
                  margin: "2vh 0",
                }}
                key={index}
                onClick={() => {
                  navigate("/index/keymap");

                  // update select product.
                  dispatch(connectedDeviceAction(true, item));
                }}
              >
                <span style={{ marginRight: "5px" }}>{item}</span>
                <img
                  src="/plus.png"
                  alt={"create devices"}
                  style={{
                    width: "7%",

                    display: "inline",
                    marginRight: "1vh",
                  }}
                />
              </div>
            ))}

            <div
              style={{
                width: "100%",
                display: "flex",
                color: "blue",
                flexDirection: "row",
                justifyContent: "center",
                border: "1px blue solid",
                borderRadius: "4px",
                alignItems: "center",
                cursor: "pointer",
                padding: "0.5vh 0",
                margin: "2vh 0",
              }}
              onClick={() => {
                navigate("/");
              }}
            >
              <img
                src="/plus.png"
                alt={"create devices"}
                style={{
                  width: "7%",

                  display: "inline",
                  marginRight: "1vh",
                }}
              />
              <span style={{ width: "40%" }}>添加设备</span>
            </div>
          </div>

          <SeparatorLine></SeparatorLine>

          <div
            className="lowerpart"
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              flex: 1,
              width: "100%",
            }}
          >
            {configItems.map((item, index) => (
              <span style={{ marginBottom: "2vh" }} key={index}>
                {item}
              </span>
            ))}
          </div>
        </div>

        <span style={{ marginBottom: "2vh" }}>版本信息</span>
      </div>
    );
  } else {
    return (
      <div
        className="sidebar"
        style={{
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        <img
          style={{
            width: "20%",
            maxWidth: "80%",
            alignSelf: "center",
            marginTop: "1vh",
            cursor: "pointer",
          }}
          src="/favicon.webp"
          alt="IQUNIX.store"
          onClick={() => {
            // window.open("https://iqunix.com", "_blank");
          }}
        ></img>
        <div
          className="upperpart"
          style={{
            display: "flex",
            flexDirection: "column",
            width: "100%",
            alignItems: "center",
            flex: 1,
            justifyContent: "flex-start",
            marginTop: "8vh",
          }}
        >
          <MenuLine
            icon="/insert.png"
            text={menuItems[0]}
            clickCallback={() => {
              navigate("/");
              setMenuSelected(1);
            }}
            select={menuSelected == 1}
          />

          <MenuLine
            icon="/insert.png"
            text={menuItems[1]}
            clickCallback={() => {
              navigate("/tools");
              setMenuSelected(2);
            }}
            select={menuSelected == 2}
          />

          <MenuLine
            icon="/insert.png"
            text={menuItems[2]}
            clickCallback={() => {
              navigate("/firmware");
              setMenuSelected(3);
            }}
            select={menuSelected == 3}
          />
        </div>

        <span style={{ width: "100%", marginBottom: "2vh" }}>版本信息</span>
      </div>
    );
  }
};

export default Sidebar;

function MenuLine({ icon, text, clickCallback, select }) {
  return (
    <div
      style={{
        cursor: "pointer",
        width: "100%",
        display: "flex",
        flexDirection: "row",
        padding: "2vh 0",
        justifyContent: "center",
        marginBottom: "4vh",
        backgroundColor: select ? "lightblue" : "white",
        // border: "1px blue solid",
        alignItems: "center",
      }}
      onClick={() => {
        clickCallback(text);
      }}
    >
      <img
        src={icon}
        alt={text}
        style={{
          width: "10%",

          display: "inline",
          marginRight: "1vh",
        }}
      />
      <span style={{ width: "30%" }}>{text}</span>
    </div>
  );
}
