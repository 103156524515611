function OtherToolsPage() {
  return (
    <div
      className="device-container"
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexWrap: "wrap",
        backgroundColor: "#f2f4f5",
        width: "100%",
        padding: "3vh 4vh",
        margin: 0,
        flex: 1,
      }}
    >
      <div> OtherToolsPage </div>
      <div> OtherToolsPage </div>
    </div>
  );
}

export default OtherToolsPage;
