import "./i18n";
import React, { useEffect, useState } from "react";
import "./App.css";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Navigate,
  useNavigate,
} from "react-router-dom";

import NotFoundPage from "./pages/NotFoundPage";

import MainStructure from "./pages/MainStructure";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import commonEn from "./locales/en.json";
import commonZh from "./locales/zh.json";
import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import KeyMapsPage from "./KeyMapsPage";
import LightnessPage from "./LightnessPage";
import SuperKeysPage from "./SuperKeysPage";
import SettingsPage from "./SettingsPage";
import IndexEntry from "./IndexEntry";
import OtherToolsPage from "./OtherToolsPage";
import DeviceListPage from "./DeviceListPage";
import FirmwareInfoList from "./FirmwareInfoList";
import FastTriggerPage from "./FastTriggerPage";

const App = () => {
  const common = useSelector((state) => state.persistent.common);
  const appConfig = common.appConfig;

  // disable senstive log
  // console.log = function () {};
  // console.warn = function () {};
  const [isInitialized, setIsInitialized] = useState(false);

  useEffect(() => {
    const initializeI18n = async () => {
      await i18n.use(initReactI18next).init({
        resources: {
          en: {
            translation: commonEn,
          },
          zh: {
            translation: commonZh,
          },
        },
        lng: appConfig.lang,
        fallbackLng: "en",
        interpolation: {
          escapeValue: false,
        },
      });
      setIsInitialized(true); // Set initialization to true after completion
    };

    initializeI18n();
  }, []);

  if (!isInitialized) {
    return <div></div>; // Render loading indicator while initializing
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={<IndexEntry />}>
          <Route index path="/" element={<DeviceListPage />} />
          <Route path="/index" element={<MainStructure />}>
            <Route path="keymap" element={<KeyMapsPage />} />
            <Route path="light" element={<LightnessPage />} />
            <Route path="fasttrigger" element={<FastTriggerPage />} />
            <Route path="superkey" element={<SuperKeysPage />} />
            <Route path="settings" element={<SettingsPage />} />
          </Route>
          <Route path="/firmware" element={<FirmwareInfoList />} />
          <Route path="/tools" element={<OtherToolsPage />} />
        </Route>
        {/* Default route */}
        <Route path="*" element={<Navigate to="/not-found" />} />
        {/* Not Found route */}
        <Route path="/not-found" element={<NotFoundPage />} />
      </Routes>
    </Router>
  );
};

export default App;
