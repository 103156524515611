// import { useTranslation } from "react-i18next";
import i18next from "i18next";
import commonEn from "../locales/en.json";
import commonZh from "../locales/zh.json";
import { getInitialLanguage } from "../webhid/commonReducer";

i18next.init({
  resources: {
    en: {
      translation: commonEn, // 使用 common.json 的内容
    },
    zh: {
      translation: commonZh, // 使用 common.json 的内容
    },
  },
  lng: getInitialLanguage(),
  fallbackLng: "en",
  interpolation: {
    escapeValue: false, // react already safes from xss
  },
});

let mouseKeyMap = [];
let systemKeyMap = [];

console.log("#################################");
function getPredefinedKeyById(id) {
  let obj = systemKeyMap.find((item) => item.id === id);
  return obj.code;
}

function getPredefinedKeyByName(name) {
  let obj = systemKeyMap.find((item) => item.name === name);
  if (obj) {
    return obj.code;
  } else {
    return undefined;
  }
}

function updateKeyMaps() {
  mouseKeyMap = [
    i18next.t("click_leftbutton"),
    i18next.t("wheel_button"),
    i18next.t("click_rightbutton"),
    i18next.t("backward_button"),
    i18next.t("forward_button"),
  ];

  systemKeyMap = [
    {
      id: "1",
      name: i18next.t("click_leftbutton"),
      code: "04 01 00",
    },
    {
      id: "2",
      name: i18next.t("click_rightbutton"),
      code: "04 02 00",
    },
    {
      id: "3",
      name: i18next.t("wheel_button"),
      code: "04 04 00",
    },
    {
      id: "4",
      name: i18next.t("forward_button"),
      code: "04 10 00",
    },
    {
      id: "5",
      name: i18next.t("backward_button"),
      code: "04 08 00",
    },
    {
      id: "6",
      name: i18next.t("play_stop"),
      code: "02 CD 00",
    },
    {
      id: "7",
      name: i18next.t("volume_down"),
      code: "02 EA 00",
    },
    {
      id: "8",
      name: i18next.t("volume_up"),
      code: "02 E9 00",
    },
    {
      id: "9",
      name: i18next.t("mute"),
      code: "02 E2 00",
    },
    {
      id: "10",
      name: i18next.t("player"),
      code: "02 83 01",
    },
    {
      id: "11",
      name: i18next.t("previous_track"),
      code: "02 B6 00",
    },
    {
      id: "12",
      name: i18next.t("next_track"),
      code: "02 B5 00",
    },
    {
      id: "13",
      name: i18next.t("mail"),
      code: "02 8A 01",
    },
    {
      id: "14",
      name: i18next.t("calculator"),
      code: "02 92 01",
    },
    {
      id: "998",
      name: i18next.t("default_key"),
      code: "FF FF FF",
    },
    {
      id: "997",
      name: i18next.t("fire_key"),
      code: "04 01 00 00 02",
    },
    {
      id: "996",
      name: i18next.t("tripple_click"),
      code: "04 01 00 00 03",
    },
    {
      id: "9999",
      name: i18next.t("none"),
      code: "0F 00 01",
    },
    //   {
    //     id: "999",
    //     name: "自定义",
    //     code: "",
    //   },
  ];
}

// 初始化时调用
updateKeyMaps();

// 监听语言变化
i18next.on("languageChanged", () => {
  updateKeyMaps();
});

export {
  systemKeyMap,
  mouseKeyMap,
  getPredefinedKeyById,
  getPredefinedKeyByName,
};
