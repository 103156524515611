import "./TabNavigation.css";
import { userClickBottomTabAction } from "../webhid/action";
import { useDispatch } from "react-redux";

function TabNavigation({ data, activeTab, onClickFunc }) {
  const dispatch = useDispatch();
  return (
    <div
      className="tab-navigation"
      style={{
        display: "flex",
        margin: "0.6vh auto",
        paddingBottom: "0",
        width: "100%",
        userSelect: "none",
      }}
    >
      {data.map((item, index) => (
        <button
          key={index}
          style={{
            marginRight: "5px",
            paddingBottom: "1.3vh",
            marginBottom: "-2px",
          }}
          className={activeTab === index ? "active" : ""}
          onClick={() => {
            onClickFunc(index);
            dispatch(userClickBottomTabAction(index));
          }}
        >
          {item}
        </button>
      ))}
    </div>
  );
}

export default TabNavigation;
