// 1-6
//　依据图片中布局的顺序。
// 1 左键　　２　右键　３　增加ＤＰＩ键　　４　中键　５　减少ＤＰＩ　　６　ＤＰＩ按键

export const mouseButtonMap = ["01", "02", "04", "03", "05", "06"];

export function getCommandByName(name) {
  let obj = keyboardMap.find((item) => {
    let charSet = item.name.split(" ");
    return charSet.find(
      (i) => i.toLocaleLowerCase() === name.toLocaleLowerCase()
    );
  });

  if (obj === undefined) {
    obj = keyboardMap.find(
      (item) => item.name.toLocaleLowerCase() === name.toLocaleLowerCase()
    );
  }

  if (name === " ") {
    return "2C";
  }

  if (obj === undefined) {
    console.error("we query key " + name + " failed ");
    return;
  }

  return obj.code.substring(2).padStart(2, "0");
}

export const keyboardMap = [
  {
    name: "A",
    code: "0x4",
    keys: "a",
  },
  {
    name: "B",
    code: "0x5",
    keys: "b",
  },
  {
    name: "C",
    code: "0x6",
    keys: "c",
  },
  {
    name: "D",
    code: "0x7",
    keys: "d",
  },
  {
    name: "E",
    code: "0x8",
    keys: "e",
  },
  {
    name: "F",
    code: "0x9",
    keys: "f",
  },
  {
    name: "G",
    code: "0xa",
    keys: "g",
  },
  {
    name: "H",
    code: "0xb",
    keys: "h",
  },
  {
    name: "I",
    code: "0xc",
    keys: "i",
  },
  {
    name: "J",
    code: "0xd",
    keys: "j",
  },
  {
    name: "K",
    code: "0xe",
    keys: "k",
  },
  {
    name: "L",
    code: "0xf",
    keys: "l",
  },
  {
    name: "M",
    code: "0x10",
    keys: "m",
  },
  {
    name: "N",
    code: "0x11",
    keys: "n",
  },
  {
    name: "O",
    code: "0x12",
    keys: "o",
  },
  {
    name: "P",
    code: "0x13",
    keys: "p",
  },
  {
    name: "Q",
    code: "0x14",
    keys: "q",
  },
  {
    name: "R",
    code: "0x15",
    keys: "r",
  },
  {
    name: "S",
    code: "0x16",
    keys: "s",
  },
  {
    name: "T",
    code: "0x17",
    keys: "t",
  },
  {
    name: "U",
    code: "0x18",
    keys: "u",
  },
  {
    name: "V",
    code: "0x19",
    keys: "v",
  },
  {
    name: "W",
    code: "0x1a",
    keys: "w",
  },
  {
    name: "X",
    code: "0x1b",
    keys: "x",
  },
  {
    name: "Y",
    code: "0x1c",
    keys: "y",
  },
  {
    name: "Z",
    code: "0x1d",
    keys: "z",
  },
  {
    name: "1 !",
    code: "0x1e",
    keys: "1",
  },
  {
    name: "2 @",
    code: "0x1f",
    keys: "2",
  },
  {
    name: "3 #",
    code: "0x20",
    keys: "3",
  },
  {
    name: "4 $",
    code: "0x21",
    keys: "4",
  },
  {
    name: "5 %",
    code: "0x22",
    keys: "5",
  },
  {
    name: "6 ^",
    code: "0x23",
    keys: "6",
  },
  {
    name: "7 &",
    code: "0x24",
    keys: "7",
  },
  {
    name: "8 *",
    code: "0x25",
    keys: "8",
  },
  {
    name: "9 (",
    code: "0x26",
    keys: "9",
  },
  {
    name: "0 )",
    code: "0x27",
    keys: "0",
  },
  {
    name: "D1",
    code: "0x1e",
    keys: "1",
  },
  {
    name: "D2",
    code: "0x1f",
    keys: "2",
  },
  {
    name: "D3",
    code: "0x20",
    keys: "3",
  },
  {
    name: "D4",
    code: "0x21",
    keys: "4",
  },
  {
    name: "D5",
    code: "0x22",
    keys: "5",
  },
  {
    name: "D6",
    code: "0x23",
    keys: "6",
  },
  {
    name: "D7",
    code: "0x24",
    keys: "7",
  },
  {
    name: "D8",
    code: "0x25",
    keys: "8",
  },
  {
    name: "D9",
    code: "0x26",
    keys: "9",
  },
  {
    name: "D0",
    code: "0x27",
    keys: "0",
  },
  {
    name: "- _",
    code: "0x2d",
    keys: "-",
  },
  {
    name: "= +",
    code: "0x2e",
    keys: "=",
  },
  {
    name: "` ~",
    code: "0x35",
    keys: "`",
  },
  {
    name: "[ {",
    code: "0x2f",
    keys: "[",
  },
  {
    name: "] }",
    code: "0x30",
    keys: "]",
  },
  {
    name: "\\ |",
    code: "0x31",
    keys: "\\",
  },
  {
    name: "; :",
    code: "0x33",
    keys: ";",
  },
  {
    name: "' \"",
    code: "0x34",
    keys: "'",
  },
  {
    name: ", <",
    code: "0x36",
    keys: ",",
  },
  {
    name: ". >",
    code: "0x37",
    keys: ".",
  },
  {
    name: "/ ?",
    code: "0x38",
    keys: "/",
  },
  {
    name: "F1",
    code: "0x3a",
    keys: null,
  },
  {
    name: "F2",
    code: "0x3b",
    keys: null,
  },
  {
    name: "F3",
    code: "0x3c",
    keys: null,
  },
  {
    name: "F4",
    code: "0x3d",
    keys: null,
  },
  {
    name: "F5",
    code: "0x3e",
    keys: null,
  },
  {
    name: "F6",
    code: "0x3f",
    keys: null,
  },
  {
    name: "F7",
    code: "0x40",
    keys: null,
  },
  {
    name: "F8",
    code: "0x41",
    keys: null,
  },
  {
    name: "F9",
    code: "0x42",
    keys: null,
  },
  {
    name: "F10",
    code: "0x43",
    keys: null,
  },
  {
    name: "F11",
    code: "0x44",
    keys: null,
  },
  {
    name: "F12",
    code: "0x45",
    keys: null,
  },
  {
    name: "Escape",
    code: "0x29",
    keys: "esc",
  },
  {
    name: "Space",
    code: "0x2c",
    keys: " ",
  },
  {
    name: "ContextMenu",
    code: "0x65",
    keys: null,
  },
  {
    name: "Print",
    code: "0x46",
    keys: null,
  },
  {
    name: "ScrollLock",
    code: "0x47",
    keys: null,
  },
  {
    name: "Pause",
    code: "0x48",
    keys: null,
  },
  {
    name: "Tab",
    code: "0x2b",
    keys: "tab",
  },
  {
    name: "Backspace",
    code: "0x2a",
    keys: "backspace",
  },
  {
    name: "Insert",
    code: "0x49",
    keys: "insert",
  },
  {
    name: "Delete",
    code: "0x4c",
    keys: "delete",
  },
  {
    name: "Home",
    code: "0x4a",
    keys: "home",
  },
  {
    name: "End",
    code: "0x4d",
    keys: "end",
  },
  {
    name: "PageUp",
    code: "0x4b",
    keys: "pageup",
  },
  {
    name: "PageDown",
    code: "0x4e",
    keys: "pagedown",
  },
  {
    name: "CapsLock",
    code: "0x39",
    keys: "caps_lock",
  },
  {
    name: "Enter",
    code: "0x28",
    keys: "enter",
  },
  {
    name: "ArrowLeft",
    code: "0x50",
    keys: "left",
  },
  {
    name: "ArrowDown",
    code: "0x51",
    keys: "down",
  },
  {
    name: "ArrowUp",
    code: "0x52",
    keys: "up",
  },
  {
    name: "ArrowRight",
    code: "0x4f",
    keys: "right",
  },
  {
    name: "▽",
    code: "0x1",
    keys: null,
  },
  {
    name: "1",
    code: "0x59",
    keys: "num_1",
  },
  {
    name: "2",
    code: "0x5a",
    keys: "num_2",
  },
  {
    name: "3",
    code: "0x5b",
    keys: "num_3",
  },
  {
    name: "4",
    code: "0x5c",
    keys: "num_4",
  },
  {
    name: "5",
    code: "0x5d",
    keys: "num_5",
  },
  {
    name: "6",
    code: "0x5e",
    keys: "num_6",
  },
  {
    name: "7",
    code: "0x5f",
    keys: "num_7",
  },
  {
    name: "8",
    code: "0x60",
    keys: "num_8",
  },
  {
    name: "9",
    code: "0x61",
    keys: "num_9",
  },
  {
    name: "0",
    code: "0x62",
    keys: "num_0",
  },
  {
    name: "/",
    code: "0x54",
    keys: "num_divide",
  },
  {
    name: "*",
    code: "0x55",
    keys: "num_multiply",
  },
  {
    name: "-",
    code: "0x56",
    keys: "num_subtract",
  },
  {
    name: "+",
    code: "0x57",
    keys: "num_add",
  },
  {
    name: ".",
    code: "0x63",
    keys: "num_decimal",
  },
  {
    name: "NumLock",
    code: "0x53",
    keys: "num",
  },
  {
    name: "N.Ent",
    code: "0x58",
    keys: null,
  },
  {
    name: "Shift",
    code: "0xe1",
    keys: "shift",
  },
  {
    name: "RightShift",
    code: "0xe5",
    keys: null,
  },
  {
    name: "Control",
    code: "0xe0",
    keys: "ctrl",
  },
  {
    name: "RightCtrl",
    code: "0xe4",
    keys: null,
  },
  {
    name: "Left Win",
    code: "0xe3",
    keys: "cmd",
  },
  {
    name: "Meta",
    code: "0xe7",
    keys: null,
  },
  {
    name: "Alt",
    code: "0xe2",
    keys: "alt",
  },
  {
    name: "RightAlt",
    code: "0xe6",
    keys: null,
  },
];

export function getSpecialKey(keyname) {
  if (keyname === "Left Win") {
    return "01 08";
  } else if (keyname === "Meta") {
    return "01 80";
  } else if (keyname === "Shift") {
    return "01 02";
  } else if (keyname === "RightShift") {
    return "01 20";
  } else if (keyname === "Control") {
    return "01 01";
  } else if (keyname === "RightControl") {
    return "01 10";
  } else if (keyname === "Alt") {
    return "01 04";
  } else if (keyname === "Alt") {
    return "01 40";
  } else {
    return undefined;
  }
}
