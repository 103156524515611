import handleInputReport from "./hidresponse.js";

import {
  CONNECTED_DEVICE,
  RESTORE_BUTTON_TAB_CONF,
  SET_FATAL_LOG,
  CHANGE_APP_LANG,
} from "./constants";

// DEBUG
export const sendFatalLogAction = (logInfo) => ({
  type: SET_FATAL_LOG,
  payload: logInfo,
});

// SYSTEM
export const changeAppLanguage = (lang) => ({
  type: CHANGE_APP_LANG,
  payload: lang,
});

// CONNECTION
/* Connect Async action */
export const connectDeviceAction = (vender, product) => {
  return async (dispatch) => {
    if (!navigator.hid) {
      console.error(
        "Your browser does not support HID, please use chrome based browser."
      );
      dispatch(sendFatalLogAction("Your browser is not supported"));
      dispatch(connectedDeviceAction(false, null));

      return;
    }

    // const devicesConncted = await navigator.hid.getDevices();
    // for (const device of devicesConncted) {
    //   if (device.opened) {
    //     console.log("You already connected to a devices");
    //     dispatch(connectedDeviceAction(true, await device.productName));
    //     dispatch(sendFatalLogAction("Connect success."));
    //     return;
    //   }
    // }

    const devices = await navigator.hid.requestDevice({
      // filters: [{ usagePage: 0x01, usage: 0x02 }],
      filters: [
        {
          vendorId: vender,
          //productId: product,
        },
      ],
    });

    if (devices.length === 0) {
      console.log("No device selected.");
      dispatch(sendFatalLogAction("No avaiable devices."));
      //dispatch(connectedDeviceAction(false, null));
      return;
    }

    let device = devices[0];

    // try {
    //   await device.close();
    // } catch (e) {}

    console.log("we connec device", device, "current number", devices.length);
    try {
      await device.open();
      dispatch(connectedDeviceAction(true, device.productName));
      dispatch(sendFatalLogAction("Connect success."));
      document.title = device.productName;
    } catch (e) {
      console.log("Open hid devices failed with error", e);
      //dispatch(connectedDeviceAction(false, null));
      dispatch(sendFatalLogAction("Open hid devices failed with error" + e));
    }

    console.log("We add event handler here for device", device);
    device.addEventListener("inputreport", handleInputReport);
  };
};

export const connectedDeviceAction = (success, productName) => ({
  type: CONNECTED_DEVICE,
  payload: success,
  productName: productName,
});

export const restoreAllConfigsAction = () => ({
  type: "RESTORE_ALL_CONFIGS",
});

///////////////////////////New added.//////////////////////////
export const userClickTopMenuAction = (index) => ({
  type: "USER_CLICK_TOP_MENU_ACTION",
  payload: index,
});

export const userClickLayerAction = (index) => ({
  type: "USER_CLICK_LAYER_ACTION",
  payload: index,
});

export const userSetDragMappingAction = (cooridnate, keyId) => ({
  type: "USER_SET_DRAG_MAPPING_ACTION",
  payload: keyId,
  cooridnate: cooridnate,
});

export const userClickMapPendingKeyAction = (cooridnate, keyId) => ({
  type: "USER_CLICK_MAP_PENDING_KEY",
  payload: keyId,
  cooridnate: cooridnate,
});

export const userClickBottomTabAction = (index) => ({
  type: "USER_CLICK_BOTTOM_TAB_ACTION",
  payload: index,
});

export const userStartDragAction = (text, uid) => ({
  type: "USER_CLICK_START_DRAG_ACTION",
  payload: text,
  uid: uid,
});

export const userStopDragAction = () => ({
  type: "USER_CLICK_STOP_DRAG_ACTION",
});

export const userMouseUpPosAction = (x, y) => ({
  type: "USER_CLICK_MOUSE_UP_POS",
  x: x,
  y: y,
});

export const userClickKeyboardBelowAction = (content, uid) => ({
  type: "USER_CLICK_KEYBOARD_BELOW",
  payload: content,
  uid: uid,
});

export const clearAllPopupAction = () => ({
  type: "CLEAR_ALL_POPUP",
});

export const userSelectNewDevice = (name, picture) => ({
  type: "USER_SELECT_NEW_DEVICE",
  name: name,
  picture: picture,
});

export const updateWholeConfAction = (tab) => ({
  type: "BUTTON_MAP_UPDATE_CONFIG",
  payload: tab,
});

export const macroEmptyHintsAction = (text) => ({
  type: "MACRO_EMPTY_HINTS",
  payload: text,
});

export const macroConfigureUIAction = (show, index) => ({
  type: "MACRO_CONFIGURE_UI",
  payload: show,
  index: index,
});

export const connectButtonToMacroRelationship = (map) => ({
  type: "CONNECT_BUTTON_TO_MACRO_RELATION",
  payload: map,
});

export const updateActiveTabAction = (tabIndex) => ({
  type: "BUTTON_MAP_UPDATE_TAB_INDEX",
  payload: tabIndex,
});

// UI-> Settings
export const fillFirmwareVersion = (version) => ({
  type: "FILL_FIRMWARE_VERSION",
  payload: version,
});

export const fillBatteryInformationAction = (byte) => ({
  type: "FILL_BATTERY_INFO",
  payload: byte,
});

export const restoreButtonTabConfigures = (tab) => ({
  type: RESTORE_BUTTON_TAB_CONF,
  payload: tab,
});

// UI -> Macro
export const sendMacroOptionSelectedAction = (index, checked) => ({
  type: "MACRO_OPTION_CLICKED",
  index: index,
  checked: checked,
});

export const sendMacroAddedAction = (action) => ({
  type: "MACRO_ADDED",
  payload: action,
});

export const sendMacroDeletedAction = (index) => ({
  type: "MACRO_DELETED",
  index: index,
});

export const sendMacroReplaceAction = (index, macro) => ({
  type: "MACRO_REPLACED",
  index: index,
  payload: macro,
});

////////////////////////////////////////////////////////////////////

export function getShortProductName(longName) {}
