import { useSelector } from "react-redux";
import StatusBar from "./StatusBar";
import SendDataToHID from "./webhid/sendData";

// 联系我们组件
function SettingsPage({}) {
  function debugAction(code, content) {
    //button 1 2 3 4 .
    console.log("We click button", code, content);

    if (code === 4) {
      SendDataToHID(content);
    }
  }
  const common = useSelector((state) => state.persistent.common);
  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        flexDirection: "column",
        fontSize: "1rem",
        alignItems: "center",
        padding: "1vh 1vh",
        marginBottom: "1vh",
        backgroundColor: "white",
        borderRadius: "8px",
        //border: "1px blue solid",
        justifyContent: "flex-start",
        marginTop: "3vh",
        height: "50vh",
      }}
    >
      <h1>SettingsPage</h1>
      <p>SettingsPage</p>
      <StatusBar showText={common.fatalLog} onClickProp={debugAction} />
    </div>
  );
}

export default SettingsPage;
