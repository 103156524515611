// src/reducers/index.js
import { combineReducers } from "redux";
import commonReducer from "./commonReducer";
import macroReducer from "./macroreducer";
import dataSlice from "../server/RequestDeviceInfo";
import { connect } from "react-redux";
import connectReducer from "./connectReducer";

// export const rootReducer = combineReducers({
//   common: commonReducer,
//   macro: macroReducer,
//   deviceData: dataSlice,
//   connect: connectReducer,
// });

export const persistentRootReducer = combineReducers({
  common: commonReducer,
  macro: macroReducer,
  deviceData: dataSlice,
});

// export default rootReducer;
