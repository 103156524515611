async function getConnectedDevice() {
  if (!navigator.hid) {
    console.error(
      "Your browser does not support HID, please use chrome based browser."
    );
    return null;
  }

  const devicesConncted = await navigator.hid.getDevices();
  for (const device of devicesConncted) {
    if (device.opened) {
      //console.log("We return a cached device.", device);
      return device;
    }
  }

  return null;
}

export default getConnectedDevice;
