import TabNavigation from "./components/TabNavigation";
import StatusBar from "./StatusBar";
import "./LightnessPage.css";
import { useState } from "react";

// 联系我们组件
function LightnessPage({}) {
  const [progress1, setProgress1] = useState(50);

  const [progress2, setProgress2] = useState(50);

  const sliderStyle1 = {
    margin: "2vh 1vh",

    width: "80%",
    background: `
    linear-gradient(90deg, #0042da ${progress1}%, #ddd ${progress1}%)`,
  };

  const sliderStyle2 = {
    margin: "2vh 1vh",
    width: "80%",
    background: `
    linear-gradient(90deg, #0042da ${progress2}%, #ddd ${progress2}%)`,
  };
  const handleBrightChange = (e) => {
    setProgress1(e.target.value);
  };

  const handleSpeedChange = (e) => {
    setProgress2(e.target.value);
  };

  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        flexDirection: "row",
        fontSize: "1rem",
        alignItems: "center",
        padding: "1vh 1vh",
        marginBottom: "1vh",
        backgroundColor: "white",
        borderRadius: "8px",
        //border: "1px blue solid",
        justifyContent: "flex-start",
        marginTop: "3vh",
        height: "50vh",
      }}
    >
      <div
        className="leftpart"
        style={{
          height: "100%",
          width: "55%",
          display: "flex",
          paddingRight: "2vh",
          flexDirection: "column",
          borderRight: "1px #ddd solid",
          alignItems: "flex-start",
        }}
      >
        <span style={{ margin: "1vh" }}>选择模式</span>
        <div
          style={{
            width: "100%",
            flexWrap: "wrap",
            display: "flex",
            flexDirection: "row",
            //border: "1px red solid",
          }}
        >
          <LightnessButton />
          <LightnessButton />
          <LightnessButton />
          <LightnessButton />
          <LightnessButton />
          <LightnessButton />
          <LightnessButton /> <LightnessButton />
          <LightnessButton />
          <LightnessButton />
          <LightnessButton />
          <LightnessButton />
          <LightnessButton />
          <LightnessButton />
          <LightnessButton />
          <LightnessButton />
          <LightnessButton />
          <LightnessButton />
          <LightnessButton />
          <LightnessButton />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontSize: "0.7rem",
            width: "100%",
            margin: "0 1vh",
            padding: 0,
            //border: "1px red solid",
          }}
        >
          <span>亮度</span>
          <div style={{ width: "80%" }}>
            <input
              className="custom-slider"
              type="range"
              min="0"
              max="100"
              style={sliderStyle1}
              value={progress1}
              step="1"
              onChange={(e) => {
                handleBrightChange(e);
              }}
            ></input>

            <span>{progress1}%</span>
          </div>
        </div>

        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            alignItems: "center",
            fontSize: "0.7rem",
            width: "100%",
            margin: "0 4vh 0 1vh",
            padding: 0,
            //border: "1px red solid",
          }}
        >
          <span>速度</span>
          <div style={{ width: "80%" }}>
            <input
              className="custom-slider"
              type="range"
              min="0"
              max="100"
              style={sliderStyle2}
              value={progress2}
              step="1"
              onChange={(e) => {
                handleSpeedChange(e);
              }}
            ></input>

            <span style={{ width: "6ch", textAlign: "right" }}>
              {progress2}%
            </span>
          </div>
        </div>
      </div>

      <div
        className="rightpart"
        style={{
          display: "flex",
          flexDirection: "column",
          width: "45%",
          height: "100%",
          //  border: "1px solid red",
        }}
      ></div>
    </div>
  );
}

export default LightnessPage;

function LightnessButton({}) {
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "5px",
        backgroundColor: "#F2f4f5",
        width: "7.0vh",
        height: "6.2vh",
        fontSize: "0.5rem",
        margin: "1vh",
        justifyContent: "center",
      }}
    >
      <img
        style={{ width: "2vh", height: "2vh", marginBottom: "1vh" }}
        src="/record.png"
      ></img>
      <span>单色呼吸</span>
    </div>
  );
}
