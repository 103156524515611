import getConnectedDevice from "./devices";
import { convertIntToHexString } from "./constants";
import { StateStore } from "./statestore";
import { connectedDeviceAction } from "../webhid/action";

// 全局任务队列
const taskQueue = [];
let isProcessing = false; // 标志是否正在处理任务

function sleep(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

// 任务处理器
async function processTasks() {
  if (isProcessing) return; // 防止重复调用
  isProcessing = true;

  while (taskQueue.length > 0) {
    const task = taskQueue.shift(); // 取出队列中的第一个任务
    if (typeof task === "string") {
      await SendDataToHIDInner(task); // 执行任务
    }
    await sleep(180); // 全局间隔 180ms
  }

  isProcessing = false; // 任务处理完成
}

// 添加任务的函数
async function SendDataToHID(task) {
  if (typeof task !== "string") {
    throw new Error("Task must be a function");
  }
  taskQueue.push(task);
  processTasks(); // 尝试处理任务
}

async function SendDataToHIDInner(data) {
  const device = await getConnectedDevice();

  let command = data.trim();
  const sendData = command.split(" ").map((x) => parseInt(x, 16)); // Convert string to array of numbers
  console.warn(
    "We are sending custom command: ",
    Date.now(),
    sendData.map((num) => "0x" + convertIntToHexString(num, 1)).join(" ")
  );

  try {
    await device.sendReport(0xa0, new Uint8Array(sendData));
  } catch (e) {
    console.log("Sending custom command <" + command + "> error", e);

    StateStore.dispatch(connectedDeviceAction(false, await device.productName));
  }
}

export default SendDataToHID;
