function SeparatorLine({}) {
  return (
    <div
      style={{
        width: "100%",
        height: "1px",
        margin: "2vh 0",
        borderBottom: "1px dashed #d8d8d8",
      }}
    ></div>
  );
}
export default SeparatorLine;
