import { CONNECTED_DEVICE } from "./constants";

const initialState = {
  isConnected: false,
  productName: "",
  connectedDevices: [],
};

function connectReducer(state = initialState, action) {
  switch (action.type) {
    case CONNECTED_DEVICE:
      console.log(
        "we have set isConnected",
        action.payload,
        action.productName
      );

      let connectedDevices = JSON.parse(JSON.stringify(state.connectedDevices));

      if (!connectedDevices.some((item) => item === action.productName)) {
        connectedDevices.push(action.productName);
      }

      return {
        ...state,
        isConnected: action.payload,
        productName: action.productName,
        connectedDevices: connectedDevices,
      };
    default:
      return state;
  }
}

export default connectReducer;
