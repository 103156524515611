import { useCallback, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  userMouseUpPosAction,
  userStartDragAction,
  userStopDragAction,
} from "../webhid/action";

function ButtonUnit({ width, height, text, uid, selected, onClickEvent }) {
  const textSecs = text.split("\\n");

  // separator cap
  if (width > 500) {
    width = 1;
    height = 0.3;
  }
  const newW = 4 * width;
  const newH = 4 * height;

  const extraWidth = (Math.floor(width + 0.9) - 1) * 4;

  let key = -1; // 0 -> ass-enter, 1 -> height +, 2 -> height Enter.
  if (text === "Enter" || text === "+") {
    if (width > 1) {
      key = 0;
    } else if (width === 1) {
      key = text === "+" ? 1 : 2;
    }
  }

  const common = useSelector((state) => state.persistent.common);

  const dispatch = useDispatch();

  // 鼠标按下
  const handleMouseDown = useCallback((e) => {
    dispatch(userMouseUpPosAction(-800, -800));
    console.log("handleMouseDown --> We begin to drag?", text, "uid", uid);
    dispatch(userStartDragAction(text, uid));
  });

  return (
    <div
      onMouseDown={handleMouseDown}
      onMouseUp={(e) => {
        onClickEvent(true);
        dispatch(userStopDragAction());
      }}
      style={{
        position: "relative",
        width: `calc(${newW}vh + ${extraWidth}px + 2px)`,
        height: `${newH}vh`,
        fontSize: "0.6rem",
        borderRadius: "4px",
        borderTopRightRadius: key === 0 ? 0 : "4px",
        borderTopLeftRadius: "4px",
        display: "flex",
        margin: newW >= 4 ? "2px" : `2px ${newW / 2}px`,
        flexDirection: "column",
        padding: "2px",
        border:
          text.length === 0
            ? "0px solid #0045d5"
            : selected
            ? "1px solid #0045d5"
            : "1px solid #e1e1e1",
      }}
      onClick={(e) => {
        console.log("Button clicked");
        onClickEvent(!selected);
      }}
    >
      {key === 0 && (
        <div
          style={{
            position: "absolute",
            width: "7.6vh",
            height: "5.1vh",
            right: "-1px" /* 右对齐 */,
            bottom: "100%",
            borderLeft: selected ? "1px solid #0045d5" : "1px solid #e1e1e1",
            borderRight: selected ? "1px solid #0045d5" : "1px solid #e1e1e1",
            borderTop: selected ? "1px solid #0045d5" : "1px solid #e1e1e1",
            borderTopLeftRadius: "4px",
            borderTopRightRadius: "4px",
            backgroundColor: "white",
          }}
        ></div>
      )}

      {key > 0 && (
        <div
          style={{
            position: "absolute",
            width: "4.64vh",
            height: "5.4vh",
            right: "-1px" /* 右对齐 */,
            bottom: "-118%",
            borderLeft: selected ? "1px solid #0045d5" : "1px solid #e1e1e1",
            borderRight: selected ? "1px solid #0045d5" : "1px solid #e1e1e1",
            borderBottom: selected ? "1px solid #0045d5" : "1px solid #e1e1e1",
            borderTopLeftRadius: "0",
            borderTopRightRadius: "0",

            borderBottomLeftRadius: "4px",
            borderBottomRightRadius: "4px",
            backgroundColor: "white",
          }}
        ></div>
      )}

      {textSecs.map((item, index) => (
        <span key={index} style={{ userSelect: "none", fontWeight: "bold" }}>
          {item}
        </span>
      ))}

      {/* {isDragging && (
        <div
          style={{
            position: "absolute",
            width: `calc(${newW}vh + ${extraWidth}px + 2px)`,
            height: `${newH}vh`,
            margin: newW >= 4 ? "2px" : `2px ${newW / 2}px`,

            padding: "2px",
            left: newW >= 4 ? "-2px" : `-2px ${newW / 2}px`, //based on margin.
            top: newW >= 4 ? "-2px" : `-2px ${newW / 2}px`,
            border: "1px solid #0045d5",
            borderRadius: "4px",

            backgroundColor: "green",
          }}
        ></div>
      )} */}
    </div>
  );
}

export default ButtonUnit;
