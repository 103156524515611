import TabNavigation from "./components/TabNavigation";
import StatusBar from "./StatusBar";

// 联系我们组件
function LightnessPage({}) {
  return (
    <div
      style={{
        display: "flex",
        margin: 0,
        flexDirection: "column",
        fontSize: "1rem",
        alignItems: "center",
        padding: "1vh 1vh",
        marginBottom: "1vh",
        backgroundColor: "white",
        borderRadius: "8px",
        //border: "1px blue solid",
        justifyContent: "flex-start",
        marginTop: "3vh",
        height: "50vh",
      }}
    >
      <TabNavigation
        data={["1", "2"]}
        onClickFunc={(index) => {
          //setActiveTab(index);
        }}
        activeTab={0}
      />

      {/* <KeyBoardComponent /> */}
      <div
        style={{ marginTop: "1vh", display: "flex", flexDirection: "column" }}
      >
        2
      </div>
      {/* <StatusBar showText={"common.fatalLog"} onClickProp={() => {}} /> */}
    </div>
  );
}

export default LightnessPage;
